import React from 'react';
import Queries from './PointOfInterestTypeQueries';
import DataTable from '../common/DataTable';

function PointOfInterestTypesTable() {
  const getQuery = (orderBy,order) => {
    let query = null
    query = Queries.PointOfInterestTypes(orderBy,order);    
    return query
  }

  const getResults = (data) => {
    let results = []
    if(data) {
      results = data.point_of_interest_types_aggregate.nodes      
    }
    return results
  }  

  const getTotalRows = (data) => {
    let result = null;
    result = data.total_aggregate.aggregate.count;  
    return result;
  }


  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
        linkFunction: (value) => (`/point-of-interest-types/${value.id}`),
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/point-of-interest-types/${value.id}`),
      },
      {
        field: 'tag_key',
        label: 'Key',
        sortable: true,
      },
      {
        field: 'points_of_interest_aggregate.aggregate.count',
        label: 'Count',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
    ]
    
  return (
          
      <DataTable 
        name="Point of Interest Types" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
        defaultOrderBy="name"
        defaultOrder="asc"
      />
    
  );  
}

export default PointOfInterestTypesTable;