import React from 'react';
import Queries from './PostQueries';
import DataTable from '../common/DataTable';

function PostsTable({organizationId,userId}) {
  const getQuery = (orderBy,order) => {
    let query = null
    if(organizationId) {
      query = Queries.OrganizationPosts(orderBy,order)
    } else if(userId) {
      query = Queries.UserPosts(orderBy,order)
    } else {
      query = Queries.Posts(orderBy,order);
    }
    return query
  }

  const getResults = (data) => {
    let results = []
    if(data) {
      if(organizationId) {
        results = data.organizations[0].posts_aggregate.nodes.map(row=>row.post);
      } else if(userId) {
        results = data.users[0].posts_aggregate.nodes
      } else {
        results = data.posts_aggregate.nodes
      }
    }
    return results
  }  

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId) {
      result = data.organizations[0].total_aggregate.aggregate.count;
    } else if (userId) {
      result = data.users[0].total_aggregate.aggregate.count;
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }


  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
        linkFunction: (value) => (`/posts/${value.id}`),
      },
      {
        field: 'post_type',
        label: 'Type',
        sortable: true,
      },
      {
        field: 'post_text',
        label: 'Text',
        sortable: true,
      },
      {
        field: 'user.email',
        label: 'User',
        sortable: true,
        linkFunction: (value) => (`/users/${value.user.id}`),
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
    ]
    
  return (
          
      <DataTable 
        name="Posts" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
        queryVariables={
          {
            organizationId:organizationId,
            userId:userId,
          }        
        }
      />
    
  );  
}

export default PostsTable;