import { gql } from '@apollo/client';

export default {
  OrganizationPosts : function(sort,direction) { return gql`
    query OrganizationPosts($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        posts_aggregate: memberships_aggregate(
          order_by: {post:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            post {
              id              
              post_type
              post_text
              user{
                id
                email
              }
              updated_at
              created_at              
            }      
          }
          aggregate {
            count
          }  
        }
      }
    }    
  `},
  UserPosts : function(sort,direction) { return gql`
    query UserPosts($userId: Int!,$limit: Int!, $offset: Int!) {    
      users(where: {
        id: {
          _eq:$userId
        }
      }) {
        posts_aggregate: posts_aggregate(
          order_by: {${sort}:${direction}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            
              id              
              post_type
              post_text
              user{
                id
                email
              }
              updated_at
              created_at              
                  
          }
          aggregate {
            count
          }  
        }
        total_aggregate :
          posts_aggregate {
            aggregate {
              count
            }
          }
        
      }
    }    
  `},
  Posts : function(sort,direction) { return gql`
    query Posts($limit: Int!, $offset: Int!) {    
      posts_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          id          
          post_type
          post_text
          user{
            id
            email
          }            
          updated_at
          created_at
        }
        aggregate { 
          count
        }
      }
      total_aggregate :
          posts_aggregate {
            aggregate {
              count
            }
          }
        

    }    
  `},
  Post: gql`
    query Post($id: Int!) {
      posts(where:{id:{_eq:$id}}) {
        id        
        post_type
        post_text     
        user{
          id
          email
        }
        created_at
        updated_at        
      }
    }
  `};