import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import PointOfInterestType from './PointOfInterestType';
import Title from '../dashboard/Title';
import PointOfInterestTypesTable from './PointOfInterestTypesTable';

function PointOfInterestTypes() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Point of Interest Types</Title>
        <PointOfInterestTypesTable/>
      </Route>
      <Route path={`${path}/:id`}>
        <PointOfInterestType />
      </Route>
    </Switch>

  );  
  
}

export default PointOfInterestTypes;