import { useAuth0 } from '@auth0/auth0-react';
import {
  LinearProgress
} from '@material-ui/core';
import React from 'react';
import { Switch } from 'react-router-dom';
import './App.css';
import ProtectedRoute from "./auth/protected-route";
import Dashboard from "./components/dashboard/Dashboard";

function App() {
  const { 
    error,
    isLoading,    
    logout
  } = useAuth0();
  if(error){
    console.log(error)
    logout({onReturn:window.origin})
  } 
  if(isLoading){
    return (<LinearProgress/>)
  } else {
    return (
      <Switch>
        <ProtectedRoute path="/" component={Dashboard}/>                        
      </Switch>
    )
  }
}

export default App;
