import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Challenge from './Challenge';
import Title from '../dashboard/Title';
import ChallengesTable from './ChallengesTable';

function Challenges() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Challenges</Title>
        <ChallengesTable/>
      </Route>
      <Route path={`${path}/:id`}>
        <Challenge />
      </Route>
    </Switch>

  );  
  
}

export default Challenges;