import { gql } from '@apollo/client';

export default gql`
    mutation RemoveCommunityBoundary(
      $id: Int!,
    ) {
      delete_community_boundaries(where: {feature_type: {_eq: "Community"}, feature_id: {_eq: $id}, attribute_name: {_eq: "boundary"}}) {
        affected_rows
        returning {
          id
        }
      }
    }
`