import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Typography, LinearProgress, CardContent, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Alert } from '@material-ui/lab';

import Title from '../dashboard/Title';
import Queries from './ImageQueries';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

let versions = ['thumb', 'small', 'medium', 'large', 'extra_large'];

function Image() {
  const classes = useStyles();
  let { id } = useParams();

  const { loading, error, data } = useQuery(Queries.Image, {
    variables: { id },
  });

  if (loading) return <LinearProgress />;

  if (error) return <Alert severity="error">{error.message}</Alert>;

  let image = data.images[0];

  let base_image_url = `https://cdn.outerspatial.com/uploads/image/uploaded_file`;

  let original_url = `${base_image_url}/${id}/${image.uploaded_file}`;

  return (
    <div className={classes.root}>
      <Title>Image</Title>
      <Typography variant="h5">{image.uploaded_file || `Unnamed`}</Typography>

      <br />
      {versions.map((version) => {
        let version_url = `${base_image_url}/${image.id}/${version}_${image.uploaded_file}`;
        let square_version_url = `${base_image_url}/${image.id}/${version}_square_${image.uploaded_file}`;
        return (
          <div>
            <Card key={version}>
              <CardContent>
                <h4>{version}</h4>
                <img alt={image.caption} src={version_url} />
                <h5>
                  <a href={version_url}>{version_url}</a>
                </h5>
              </CardContent>
            </Card>
            <br />
            <Card key={`${version}_square`}>
              <CardContent>
                <h4>{version} square</h4>
                <img alt={image.caption} src={square_version_url} />
                <h5>
                  <a href={square_version_url}>{square_version_url}</a>
                </h5>
              </CardContent>
            </Card>
            <br />
          </div>
        );
      })}
      <Card>
        <CardContent>
          <img alt={image.caption} src={original_url} />
          <a href={original_url}>{original_url}</a>
        </CardContent>
      </Card>
      <br />
      <Link to="/images">Back to Images</Link>
    </div>
  );
}

export default Image;
