import React from 'react';
import {
  Formik,
  Form,
  Field } from 'formik';
import * as Yup from 'yup';
import {
  TextField
} from 'formik-material-ui';
import {
  LinearProgress,
  Button,
  Grid,
} from '@material-ui/core'
import UpdateCustomer from './queries/UpdateCustomer'
import InsertCustomer from './queries/InsertCustomer'
import { useHistory } from "react-router-dom";

import { useMutation } from '@apollo/client';

const CustomerSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short!')
    .max(500, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(1, 'Too Short!')
    .max(5000, 'Too Long!')    
})

const defaultValues = {
  name: "",
  description: "",
}

const handleError = (error) => {
  console.warn(error)
}

export default function CustomerForm({customer,onSave}) {
  let isNew = customer.id === 'new';
  let history = useHistory();

  const [insertCustomer, { loading: insertLoading }] = useMutation(InsertCustomer, {onError:handleError});
  const [updateCustomer, { loading: updateLoading }] = useMutation(UpdateCustomer, {onError:handleError});

  let isLoading = insertLoading || updateLoading

  // Since GraphQL returns null values for empty fields, we need to changed these to empty strings
  //  in order for Formik to have controlled inputs
  const initializeValues = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)))

  const initialValues = isNew ? initializeValues(defaultValues) : initializeValues(customer)
  
  const handleSubmit = (values, { setSubmitting }) => {
      setSubmitting(true);
      /*
        This is shorthand for:

        let customer = {
          id: values.id,
          name: values.name,
          description: values.description
        }
      */
      let customer = (({name, description }) => {
        return {name, description}
      })(values)

      // Add id value for existing customer
      if (!isNew){
        customer = {id: values.id, ...customer}
      }

      if(isNew){
        insertCustomer({
          variables:{
            customer:customer
          }
        }).then((response)=>{
          let id = response.data.insert_customers_one.id
          history.push(`/customers/${id}`)
          onSave()
        })
      } else {
        updateCustomer({variables: customer}).then(()=>{                  // add organizations          
          onSave()          
        })
      }
      setSubmitting(false);
  }

  return(
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={CustomerSchema}
      onSubmit={handleSubmit}
    >
    {({ submitForm, values, isSubmitting, isValid, dirty }) => (
      <Form>
        <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="name"
                label="Name"
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="description"
                fullWidth={true}
                variant="outlined"
                label="Description"
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting || isLoading || !dirty}
                onClick={submitForm}
              >
                Save
              </Button>
            </Grid>
        </Grid>

        {isSubmitting && <LinearProgress />}

      </Form>
    )}
  </Formik>
  )
}