import React from 'react';

import Queries from '../organizations/OrganizationQueries';
import DataTable from '../common/DataTable';
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete';

function CommunityOrganizationsTable({communityId,orderBy,order,removeOrganizationFromCommunity}) {
  const getQuery = (orderBy,order) => {
    var query = Queries.Organizations(orderBy,order);
  
    if(communityId) {
      query = Queries.CommunityOrganizations(orderBy,order);
    }
    return query;
  }

  const getResults = (data) => {
    let results = [];
    if(data){
      if(communityId){
        results = data.communities[0].organizations_aggregate.nodes.map(row=>row.organization)
      } else {
        results = data.organizations_aggregate.nodes;
      }
    }
    return results;
  }

  const getTotalRows = (data) => {
    var result = null;
    if(communityId) {
      result = data.communities[0].total_aggregate.aggregate.count
    } else {
      result = data.total_aggregate.aggregate.count
    }
    return result;
  }

  let rowMetadata = [
      {
        field: 'removeOrganization',
        label: 'Remove from Community?',
        sortable: false,
        component: (row) => {
          return(
            <IconButton
              aria-label="delete"
              onClick={() => { removeOrganizationFromCommunity(row.id) }}
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      },
      {
        field: 'id',
        label: 'ID',
        sortable: true,
        linkFunction: (value) => (`/organizations/${value.id}`),
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/organizations/${value.id}`),
      },
      {
        field: 'community_count.aggregate.count',
        label: 'Communities',
        sortable: false,
      },
      {
        field: 'stewardships_aggregate.aggregate.count',
        label: 'Stewardships',
      },
      {
        field: 'area_stewardships_aggregate.aggregate.count',
        label: 'Areas',
      },
      {
        field: 'trail_stewardships_aggregate.aggregate.count',
        label: 'Trails',
      },
      {
        field: 'point_of_interest_stewardships_aggregate.aggregate.count',
        label: 'POIs',
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
    ]

  return (

      <DataTable
        name="Organizations"
        defaultOrderBy={orderBy}
        defaultOrder={order}
        rowMetadata={rowMetadata}
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
        queryVariables={
          {
            communityId:communityId
          }
        }
      />

  );
}

export default CommunityOrganizationsTable;