import { gql } from '@apollo/client';

export const Fragments = {
  imageParts: gql`
    fragment ImageParts on images {
      id
      caption
      uploaded_file
      updated_at
      created_at
    }
  `
}

export default {
  OrganizationImages: function (sort, direction) {
    return gql`
      query OrganizationImages($organizationId: Int!,$limit: Int!, $offset: Int!) {    
        organizations(where: {
          id: {
            _eq: $organizationId
          }
        }) {
          images_aggregate: image_attachments_aggregate(
            order_by: {image:{${sort}:${direction}}},
            limit: $limit,
            offset: $offset) {                
            nodes {
              image {
                ...ImageParts
              }      
            }
            aggregate {
              count
            }  
          }
        }
      }
      ${Fragments.imageParts}    
    `
  },
  AreaImages: function (sort, direction) {
    return gql`
    query AreaImages($areaId: Int!,$limit: Int!, $offset: Int!) {    
      areas(where: {
        id: {
          _eq:$areaId
        }
      }) {
        images_aggregate: image_attachments_aggregate(
          order_by: {image:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            image {
              ...ImageParts
            }      
          }
          aggregate {
            count
          }  
        }
      }
    }
    ${Fragments.imageParts}    
  `
  },
  TrailImages: function (sort, direction) {
    return gql`
      query TrailImages($limit: Int!, $offset: Int!, $trailId: Int!) {    
        trails(where: {
          id: {
            _eq: $trailId
          }
        }) {
          images_aggregate: image_attachments_aggregate(
            order_by: {
              image: {
                ${sort}: ${direction}
              }
            },
            limit: $limit,
            offset: $offset) {                
            nodes {
              image {
                ...ImageParts
              }
            }
            aggregate {
              count
            }
          }
        }
      }
      ${Fragments.imageParts}
    `
  },
  Images: function (sort, direction) {
    return gql`
    query Images($limit: Int!, $offset: Int!) {    
      images_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...ImageParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate : images_aggregate {      
        aggregate {
          count
        }
      }        
    }    
    ${Fragments.imageParts}
  `;
  },
  Image: gql`
    query Image($id: Int!) {
      images(where: { id: { _eq: $id } }) {
        ...ImageParts
      }
    }
    ${Fragments.imageParts}
  `,
  NewImage: gql`
    query Image($id: Int!) {
      image(id: $id) {
        id
        caption
        uploaded_file
      }
    }
  `,
};
