import { gql } from '@apollo/client';

export default gql`
    query Search($term: String!,$limit:Int!,$id_term: Int, $uid_term:String) {
        t: trails(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}},
                {uid:{_eq:$uid_term}},                
            ]
        },order_by:{name:asc},limit:$limit) {
            i: id
            n: name
        }
        o: organizations(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]
        },order_by:{name:asc},limit:$limit) {
            id
            name
        }
        a: areas(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}},                
            ]
        },order_by:{name:asc},limit:$limit) {
            id
            name
        }
        i: images(where:{            
            _or: [
                {caption:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]
        },order_by:{caption:asc},limit:$limit) {
            id
            caption
        }
        p: points_of_interest(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]
        },order_by:{name:asc},limit:$limit) {
            id
            name
        }
        u: users(where:{
            _or: [
                {email:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]
        },order_by:{email:asc},limit:$limit) {
            id
            email
        }
        cb: content_bundles(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]
        },order_by:{name:asc},limit:$limit) {
            id
            name
        }
        out: outings(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]            
        },order_by:{name:asc},limit:$limit) {
            id
            name
        }
        e: events(where:{
            _or: [
                {name:{_ilike:$term}},
                {id:{_eq:$id_term}}
            ]
        },order_by:{name:asc},limit:$limit) {
            id
            name
        }

    }
`