import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useAuth } from '../../contexts/AuthContext';

import Title from './Title';

export function Tiles() {
  const accessToken = useAuth();

  const publish = (tileset) => {
    console.log(`publishing ${tileset}...`);
    fetch(`${process.env.REACT_APP_EXPORT_API_URL}/export/tiles/${tileset}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({
        publish: true,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(`${tileset} Publishing`);
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title>Tiles</Title>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            publish('trail-segments');
          }}
        >
          Publish Trail Segments
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            publish('trails');
          }}
        >
          Publish Trails
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            publish('areas');
          }}
        >
          Publish Areas
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            publish('pois');
          }}
        >
          Publish POIs
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            publish('outings');
          }}
        >
          Publish Outings
        </Button>

        <br />
      </Grid>
    </Grid>
  );
}
