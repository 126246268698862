import { gql } from '@apollo/client';

export const Fragments = {
  objectLogParts: gql`
    fragment ObjectLogParts on object_logs {
      id              
      created_at
      loggable_type
      loggable_id
      level
      message
    }      
  `
}

export default {
  ObjectLogs : function(sort,direction) { return gql`
    query ObjectLogs($limit: Int!, $offset: Int!) {    
      object_logs_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...ObjectLogParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate: object_logs_aggregate {
        aggregate {
          count
        }
      }
            
    }
    ${Fragments.objectLogParts}    
  `},
  ObjectLog: gql`
    query ObjectLog($id: Int!) {
      object_logs(where:{id:{_eq:$id}}) {
        ...ObjectLogParts        
      }
    }
    ${Fragments.objectLogParts}
  `};