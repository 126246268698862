import React from 'react';

import {
  Button,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {  
  Alert
} from '@material-ui/lab';
import Queries from './PointOfInterestTypeQueries';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { useMutation } from '@apollo/client';

import { 
  Formik, 
  Form,  
  Field } from 'formik';

const Schema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  tag_key: Yup.string()
    .min(4, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  category: Yup.string()
    .min(4, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  rank: Yup.string()
    .min(4, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),

  });

const defaultValues = {
  name: 'New Type',
  tag_key: 'new_type',
  rank: 'Primary',
  category: 'Activities',
}

export default function PointOfInterestTypeForm({pointOfInterestType}) {
  const [update, { loading: mutationLoading, error: mutationError }] = useMutation(Queries.UpdatePointOfInterestType);

  return (
  <Formik
    initialValues={pointOfInterestType || defaultValues}
    validationSchema={Schema}
    onSubmit={(values, { setSubmitting }) => {      
      let variables = (({ id, name, tag_key, category, rank }) => 
        ({id, name, tag_key, category, rank}))(values)      
      update({variables: variables});
      setSubmitting(false);
    }}
  >
    {({ submitForm, isSubmitting, isValid, dirty }) => (
      <Form onSubmit={submitForm}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              name="name"          
              label="Name"
              variant="outlined"
              fullWidth={true}
              margin="normal"
              autoFocus  
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              margin="normal"
              name="tag_key"          
              label="Key"
              variant="outlined"          
              fullWidth={true}              
            />
          </Grid>    
          <Grid item xs={12}>
            <Field
              component={TextField}
              margin="normal"
              name="category"          
              label="Category"
              variant="outlined"          
              fullWidth={true}              
            />
          </Grid>    
          <Grid item xs={12}>
            <Field
              component={TextField}
              margin="normal"
              name="rank"          
              label="Rank"
              variant="outlined"          
              fullWidth={true}              
            />
          </Grid>    
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"          
              onClick={submitForm}
              disabled={!isValid || isSubmitting || mutationLoading || !dirty}
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
        {isSubmitting && <LinearProgress />}
        { mutationError &&
          <Alert severity='error'>{mutationError.message}</Alert>
        }
      </Form>)}
    </Formik>
  );
}