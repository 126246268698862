import React, { useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import useInterval from '@use-it/interval';

export default function Clock() {
  let [currentDate,setCurrentDate] = useState((new Date()).toISOString())
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    
  useInterval(()=>{
    setCurrentDate((new Date()).toISOString());
  },100);

  return (
    <Moment interval={2} format="YYYY-MM-DD HH:mm:ss" tz={timeZone}>
      {currentDate}
    </Moment>
  )
}