import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Organization from './Organization';
import NewOrganization from './NewOrganization';
import OrganizationsList from './OrganizationsList'

function Organizations({community}) {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <OrganizationsList />
      </Route>
      <Route path={`${path}/:id`}>
        <Organization />
      </Route>
      <Route path={`${path}/new`}>
        <NewOrganization />
      </Route>
    </Switch>
  );

}

export default Organizations;