import React from 'react';
import {
  Grid
} from '@material-ui/core';
import ReactJson from 'react-json-view'
import packageData from '../../../package.json'
import Title from './Title';

function Debug() {
  
  return (
    
    <Grid container>
      <Grid item xs={12}>
        <Title>Debug</Title>
      </Grid>
      
      <Grid item xs={12}>
      
        <ReactJson 
          src={process.env} 
          theme="summerfruit:inverted"
          collapsed={false}
          groupArraysAfterLength={25}  
          displayDataTypes={false} 
          enableClipboard={false}
        />

        <ReactJson 
          src={packageData} 
          theme="summerfruit:inverted"
          collapsed={false}
          groupArraysAfterLength={25}  
          displayDataTypes={false} 
          enableClipboard={false}
        />

      </Grid>
      
    </Grid>
    
  );  
  
}

export default Debug;