import { gql } from '@apollo/client';

export const Fragments = {
  trailParts: gql`
    fragment TrailParts on trails {
      id              
      name
      updated_at
      created_at
      org_count: stewardships_aggregate {
        aggregate {
          count
        }
      }
    }      
  `,
  contentParts: gql`
    fragment ContentParts on trails {
      content_blocks_aggregate {
        aggregate {
          count
        }
      }
      # bookmarks_aggregate {
      #   aggregate {
      #     count(distinct: true)
      #   }
      # }
      content_bundle_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      event_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      paper_map_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      # posts_aggregate {
      #   aggregate {
      #     count(distinct: true)
      #   }
      # }
      stewardships_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      tags_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      web_link_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
    }
  ` 
}

export default {
  OrganizationTrails: function(sort,direction) { return gql`
    query OrganizationTrails($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        trails_aggregate: trail_stewardships_aggregate(
          order_by: {trail:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            trail {
              ...TrailParts  
              ...ContentParts              
            }                        
          }
          aggregate {
            count
          }  
        }
        total_aggregate: trail_stewardships_aggregate {
          aggregate {
           count
          }
        }

      }
    }    
    ${Fragments.trailParts}
    ${Fragments.contentParts}
  `},
  AreaTrails: function(sort,direction) { return gql`
    query AreaTrails($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      area(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        trails_aggregate: trail_stewardships_aggregate(
          order_by: {trail:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            trail {
              ...TrailParts  
              ...ContentParts              
            }                        
          }
          aggregate {
            count
          }  
        }
        total_aggregate: trail_stewardships_aggregate {
          aggregate {
          count
          }
        }

      }
    }    
    ${Fragments.trailParts}
    ${Fragments.contentParts}
  `},
  Trails: function(sort,direction) { return gql`
    query Trails($limit: Int!, $offset: Int!) {    
      trails_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...TrailParts
          ...ContentParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate: trails_aggregate {
        aggregate {
          count
        }
      }
            
    }
    ${Fragments.trailParts}   
    ${Fragments.contentParts} 
  `},
  Trail: gql`
    query Trail($id: Int!) {
      trails(where:{id:{_eq:$id}}) {
        ...TrailParts
        stewardships: stewardships(order_by:{organization:{name:asc}}) {
          organization {
            id
            name
          }        
        }
      }
    }
    ${Fragments.trailParts}
  `,
  TrailContent: gql`
    query TrailContent($id: Int!) {
      trails(where:{id:{_eq:$id}}) {         
        id
        name
        accessibility_description
        address
        trail_type
        audio_description
        trail_content_blocks_aggregate {
          aggregate {
            count
          }
        }
        bookmarks_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        content_bundle_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        description
        created_at
        event_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        paper_map_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        parent_id
        phone_number
        points_of_interests_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        posts_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        slug
        stewardships_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        tags_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        trails_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        updated_at
        user_id
        website
        web_link_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
      }
    }`
  }
