import React, { useState } from 'react';
import {
  Formik,
  Form,
  Field } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Checkbox
} from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  LinearProgress,
  Button,
  Grid,
} from '@material-ui/core'
import Queries from './CommunityQueries'
import UpdateCommunity from './queries/UpdateCommunity'
import InsertCommunity from './queries/InsertCommunity'
import RemoveCommunityBoundary from './queries/RemoveCommunityBoundary'
import UpdateCommunityBoundary from './queries/UpdateCommunityBoundary'
import AddOrganizations from './queries/AddOrganizations'
import OrganizationComboBox from '../common/OrganizationComboBox'
import { useHistory } from "react-router-dom";

import { useMutation } from '@apollo/client';

const CommunitySchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short!')
    .max(500, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(1, 'Too Short!')
    .max(5000, 'Too Long!')
    .required('Required'),
  slug: Yup.string()
    .min(1, 'Too Short!')
    .max(500, 'Too Long!')
    .required('Required')
})

const defaultValues = {
  name: "",
  description: "",
  badge_image_id: "",
  hero_image_id: "",
  organizations: [],
  is_sponsored: false,
  slug: "",
  boundary: {
    geometry: {}
  }
}

const handleError = (error) => {
  console.warn(error)
}

export default function CommunityForm({community,onSave}) {
  let isNew = community.id === 'new';
  let history = useHistory();

  const [insertCommunity, { loading: insertLoading }] = useMutation(InsertCommunity, {onError:handleError});
  const [updateCommunity, { loading: updateLoading }] = useMutation(UpdateCommunity, {onError:handleError});
  const [updateBoundary, { loading: updateBoundaryLoading }] = useMutation(
    UpdateCommunityBoundary, {
      refetchQueries: [
        {
          query: Queries.Community,
          variables: {
            id: community.id
          }
        }
      ],
      onError:handleError
    }
  );
  const [removeBoundary, { loading: removeBoundaryLoading }] = useMutation(RemoveCommunityBoundary, {onError:handleError});

  const [newOrganizations, setNewOrganizations] = useState([]);
  const [existingOrganizations/*, setExistingOrganizations*/] = useState(community.organizations.map((item)=>{return item.organization.id}));
  const [orgsDirty, setOrgsDirty] = useState();
  const [addOrganizationsMutation, { loading: addOrganizationsLoading }] = useMutation(AddOrganizations, {onError:handleError});

  let boundaryString = community.boundary ? JSON.stringify(community.boundary.geometry) : ""
  let initialValues = {...community,boundaryString} || defaultValues
  let isLoading = insertLoading || updateLoading || updateBoundaryLoading || removeBoundaryLoading || addOrganizationsLoading

  const addOrganizations = (newOrgs) => {
    setNewOrganizations(newOrgs)
    setOrgsDirty(true)
  }

  // Since GraphQL returns null values for empty fields, we need to changed these to empty strings
  //  in order for Formik to have controlled inputs
  const initializeValues = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)))

  const handleSubmit = (values, { setSubmitting }) => {
      setSubmitting(true);
      /*
        This is shorthand for:

        let community = {
          id: values.id,
          name: values.name,
          description: values.description
        }
      */
      let community = (({name, description, badge_image_id, hero_image_id, slug }) => {
        return {name, description, badge_image_id, hero_image_id, slug}
      })(values)

      // Add id value for existing community
      if (!isNew){
        community = {id: values.id, ...community}
      }

      // Normalize empty string data from Formik to our null default values
      if (community.badge_image_id === "") {community.badge_image_id = null}
      if (community.hero_image_id === "") {community.hero_image_id = null}
      if (community.slug === "") {community.slug = null}

      if(isNew){
        insertCommunity({
          variables:{
            community:community
          }
        }).then((response)=>{
          let id = response.data.insert_communities_one.id
          history.push(`/communities/${id}`)
          onSave()
        })
      } else {
        updateCommunity({variables: community}).then(()=>{                  // add organizations
          let orgObjects = newOrganizations.map((item)=>{return {community_id:community.id,member_id:item.id,member_type:'Organization'}})
          addOrganizationsMutation({variables:{objects:orgObjects}}).then(()=>{
            setOrgsDirty(false)
            setNewOrganizations([])
            if (values.boundaryString === null || values.boundaryString.trim() === ''){
              removeBoundary({variables: {id:values.id}})
              onSave()
            } else {
              let boundaryObj = JSON.parse(values.boundaryString)
              updateBoundary({variables:{id:values.id,boundary:boundaryObj}})
              onSave()
            }
            onSave()
          })

        })
      }
      setSubmitting(false);
  }

  return(
    <Formik
      enableReinitialize={true}
      initialValues={initializeValues(initialValues)}
      validationSchema={CommunitySchema}
      onSubmit={handleSubmit}
    >
    {({ submitForm, values, isSubmitting, isValid, dirty }) => (
      <Form>
        <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="name"
                label="Name"
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="description"
                fullWidth={true}
                variant="outlined"
                label="Description"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                name="slug"
                fullWidth={true}
                variant="outlined"
                label="Slug"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                name="badge_image_id"
                fullWidth={true}
                variant="outlined"
                label="Badge Image ID"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                name="hero_image_id"
                fullWidth={true}
                variant="outlined"
                label="Hero Image ID"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    checked={values.is_sponsored}
                    name="is_sponsored"
                  />
                }
                label="Sponsored"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                multiline={true}
                rows={10}
                name="boundaryString"
                fullWidth={true}
                variant="outlined"
                label="Boundary"
              />
            </Grid>
            {!isNew &&
            <Grid item xs={12}>
              <OrganizationComboBox label="Add Organizations" onChange={addOrganizations} value={newOrganizations} filterIds={existingOrganizations}/>
            </Grid>
            }
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting || isLoading || !(dirty || orgsDirty)}
                onClick={submitForm}
              >
                Save
              </Button>
            </Grid>
        </Grid>

        {isSubmitting && <LinearProgress />}

      </Form>
    )}
  </Formik>
  )
}