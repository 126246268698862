import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import {
  PostAdd,
  Dashboard,
  Business,
  People,
  Assignment,
  Explore,
  List,
  NaturePeople,
  ArrowRight,
  Build,
  LocationOn,
  Search,
  PhotoLibrary,
} from '@material-ui/icons';

import { Link } from 'react-router-dom';

export const mainListItems = (
  <div>
    <Link to="/search">
      <ListItem button>
        <ListItemIcon>
          <Search />
        </ListItemIcon>
        <ListItemText primary="Search" />
      </ListItem>
    </Link>

    <Link to="/communities">
      <ListItem button>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Communities" />
      </ListItem>
    </Link>
    <Link to="/organizations">
      <ListItem button>
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary="Organizations" />
      </ListItem>
    </Link>
    <Link to="/customers">
      <ListItem button>
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItem>
    </Link>
    <Link to="/users">
      <ListItem button>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    </Link>
    <Link to="/posts">
      <ListItem button>
        <ListItemIcon>
          <PhotoLibrary />
        </ListItemIcon>
        <ListItemText primary="Posts" />
      </ListItem>
    </Link>
    <Link to="/images">
      <ListItem button>
        <ListItemIcon>
          <PostAdd />
        </ListItemIcon>
        <ListItemText primary="Images" />
      </ListItem>
    </Link>
    <Link to="/files">
      <ListItem button>
        <ListItemIcon>
          <PostAdd />
        </ListItemIcon>
        <ListItemText primary="Files" />
      </ListItem>
    </Link>
    <Link to="/challenges">
      <ListItem button>
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText primary="Challenges" />
      </ListItem>
    </Link>
    <Link to="/areas">
      <ListItem button>
        <ListItemIcon>
          <NaturePeople />
        </ListItemIcon>
        <ListItemText primary="Areas" />
      </ListItem>
    </Link>
    <Link to="/trails">
      <ListItem button>
        <ListItemIcon>
          <NaturePeople />
        </ListItemIcon>
        <ListItemText primary="Trails" />
      </ListItem>
    </Link>
    <Link to="/areas/content">
      <ListItem button>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
        <ListItemText primary="Area Content" />
      </ListItem>
    </Link>
    <Link to="/points-of-interest">
      <ListItem button>
        <ListItemIcon>
          <LocationOn />
        </ListItemIcon>
        <ListItemText primary="Points of Interest" />
      </ListItem>
    </Link>
    <Link to="/point-of-interest-types">
      <ListItem button>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
        <ListItemText primary="Point of Interest Types" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <Link to="/search-ids">
      <ListItem button>
        <ListItemIcon>
          <Search />
        </ListItemIcon>
        <ListItemText primary="Search IDs" />
      </ListItem>
    </Link>

    <Link to="/landscape-map">
      <ListItem button>
        <ListItemIcon>
          <Explore />
        </ListItemIcon>
        <ListItemText primary="Landscape Map" />
      </ListItem>
    </Link>
    <Link to="/xray-map">
      <ListItem button>
        <ListItemIcon>
          <Explore />
        </ListItemIcon>
        <ListItemText primary="X-Ray Map" />
      </ListItem>
    </Link>
    <Link to="/view-content-map">
      <ListItem button>
        <ListItemIcon>
          <Explore />
        </ListItemIcon>
        <ListItemText primary="View Content Map" />
      </ListItem>
    </Link>
    <Link to="/change-email">
      <ListItem button>
        <ListItemIcon>
          <List />
        </ListItemIcon>
        <ListItemText primary="Change Email" />
      </ListItem>
    </Link>
    <Link to="/object-logs">
      <ListItem button>
        <ListItemIcon>
          <List />
        </ListItemIcon>
        <ListItemText primary="Object Logs" />
      </ListItem>
    </Link>
    <Link to="/tools">
      <ListItem button>
        <ListItemIcon>
          <Build />
        </ListItemIcon>
        <ListItemText primary="Tools" />
      </ListItem>
    </Link>
    <Link to="/tiles">
      <ListItem button>
        <ListItemIcon>
          <Build />
        </ListItemIcon>
        <ListItemText primary="Tiles" />
      </ListItem>
    </Link>
  </div>
);
