import { gql } from '@apollo/client';

export default {
  TrailCommunities : function(sort,direction) { return gql`
    query TrailCommunities($trailId: Int!,$limit: Int!, $offset: Int!) {
      community_trails_aggregate(where: {
          trail_id: {
            _eq:$trailId
          }
        },
        order_by: {
          community:{${sort}:${direction}}
        },
        limit: $limit,
        offset: $offset)
      {
        nodes {
          community {
            id
            name
            updated_at
            org_count: organization_community_memberships_aggregate {
                aggregate {
                  count
                }
              }
          }
        }
        aggregate {
          count
        }
      }
    }
  `},
  AreaCommunities : function(sort,direction) { return gql`
    query AreaCommunities($areaId: Int!,$limit: Int!, $offset: Int!) {
      community_areas_aggregate(where: {
          area_id: {
            _eq:$areaId
          }
        },
        order_by: {
          community:{${sort}:${direction}}
        },
        limit: $limit,
        offset: $offset)
      {
        nodes {
          community {
            id
            name
            updated_at
            org_count: organization_community_memberships_aggregate {
                aggregate {
                  count
                }
              }
          }
        }
        aggregate {
          count
        }
      }
    }
  `},
  OrganizationCommunities : function(sort,direction) { return gql`
    query OrganizationCommunities($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        communities_aggregate: community_memberships_aggregate(
          order_by: {community:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            community {
              id
              name
              updated_at
              org_count: organization_community_memberships_aggregate {
                aggregate {
                  count
                }
              }
            }      
          }
          aggregate {
            count
          }  
        }
        total_aggregate: 
          community_memberships_aggregate {
            aggregate {
              count
            }          
        }
      }
    }    
  `},
  Communities : function(sort,direction) { return gql`
    query Communities($limit: Int!, $offset: Int!) {    
      communities_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          id
          name            
          updated_at
          org_count: organization_community_memberships_aggregate {
            aggregate {
              count
            }
          }
          area_count {
            count
          }
          outing_count {
            count
          }
          point_of_interest_count {
            count
          }
          trail_count {
            count
          }
          trail_miles {
            length_miles
          }

        }
        aggregate { 
          count
        }
      }
      total_aggregate: communities_aggregate {
        aggregate {
          count
        }
      }
    }    
  `},
  Community: gql`
    query Community($id: Int!) {
      communities(where:{id:{_eq:$id}}) {
        id
        name
        logo
        slug
        badge_image_id
        hero_image_id
        is_sponsored
        description
        created_at
        updated_at
        boundary {
          geometry
        }
        organizations: organization_community_memberships(order_by:{organization:{name:asc}}) {
          id
          organization_id
          organization {
            id
            name
          }        
        }
      }
    }
  `};