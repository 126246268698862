import { gql } from '@apollo/client';

export default gql`
    mutation UpdateCustomer(
      $id: Int!,
      $name: String!,
      $description: String!,
    ) {
      update_customers(
        where: {id: {_eq: $id}}
          _set: {
            name: $name,
            description: $description,
          }
      ) {
        affected_rows
        returning {
          id
          name
          created_at
          updated_at
          description
        }
      }
    }
`