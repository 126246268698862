import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Paper, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../dashboard/Title';
import Queries from './AreaQueries';
import AreaTabs from './AreaTabs';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: '10px',
    marginBottom: '10px'
  }
});

function Area() {
  const classes = useStyles();
  let { id } = useParams();

  const { loading, error, data } = useQuery(Queries.Area, {
    variables: { id },
  });

  if (loading) return <LinearProgress />;
  if (error) return <p>Error :( {error.message}</p>;

  let { name } = data.areas[0];

  return (
    <div className={classes.root}>
      <Title>Area</Title>
      <Typography variant='h5'>{name || `Unnamed`}</Typography>
      <Paper className={classes.paper}><AreaTabs areaId={id} /></Paper>
      <Link to='/areas'>Back to Areas</Link>
    </div>
  );
}

export default Area;
