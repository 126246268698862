import React from 'react';

import Queries from './ObjectLogQueries';

import DataTable from '../common/DataTable';

function ObjectLogsTable() {
  const getQuery = (orderBy,order) => {    
    var query = 
      Queries.ObjectLogs(orderBy,order);
    return query;
  }

  const getTotalRows = (data) => {
    let result = null;
    result = data.total_aggregate.aggregate.count;    
    return result;
  }

  const getResults = (data) => {
    var results = data ? 
      data.object_logs_aggregate.nodes : [];
    return results;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Date',
        sortable: true,        
      },
      {
        field: 'level',
        label: 'Level',
        sortable: true,        
      },
      {
        field: 'loggable_type',
        label: 'Type',
        sortable: true,        
      },
      {
        field: 'loggable_id',
        label: 'ID',
        sortable: true,        
      },

      {
        field: 'message',
        label: 'Message',        
      },
    ]
    
  return (        
      <DataTable 
        name="ObjectLogs" 
        rowMetadata={rowMetadata} 
        getResults={getResults}
        getTotalRows={getTotalRows}
        getQuery={getQuery}        
      />    
  );  
}

export default ObjectLogsTable;