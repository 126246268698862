import { gql } from '@apollo/client'

export const Fragments = {
  organizationParts: gql`
    fragment OrganizationParts on organizations {
      created_at
      description
      email_domain
      id
      is_verified
      license_tier
      logo_image_id
      membership_limit
      name
      slug
      updated_at

      community_count: organization_community_memberships_aggregate {
        aggregate {
          count
        }
      }

      communities: community_memberships(order_by: {community: {name: asc}}) {
        community {
          id
          name
          updated_at
          org_count: organization_community_memberships_aggregate {
            aggregate {
              count
            }
          }
        }
      }

      stewardships_aggregate {
        aggregate {
          count
        }
      }

      area_stewardships_aggregate {
        aggregate {
          count
        }
      }

      trail_stewardships_aggregate {
        aggregate {
          count
        }
      }     

      point_of_interest_stewardships_aggregate {
        aggregate {
          count
        }
      }
    }      
  `
}

export default {  
  CommunityOrganizations : function(sort,direction) { return gql`
    query CommunityOrganizations($communityId: Int!,$limit: Int!, $offset: Int!) {    
      communities(where: {
        id: {
          _eq:$communityId
        }
      }) {
        organizations_aggregate: organization_community_memberships_aggregate(
          order_by: {organization:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            id
            organization{
              ...OrganizationParts
            }
          }
          aggregate {
            count
          }  
        }
        total_aggregate: organization_community_memberships_aggregate {
          aggregate {
            count
          }
        }
      }
    }    
    ${Fragments.organizationParts}
  `},
   TrailOrganizations : function(sort,direction) { return gql`
   query TrailOrganizations($trailId: Int!,$limit: Int!, $offset: Int!) {    
     trails(where: {
       id: {
         _eq:$trailId
       }
     }) {
       organizations_aggregate: stewardships_aggregate(
         order_by: {organization:{${sort}:${direction}}},
         limit: $limit,
         offset: $offset) {                
         nodes {
           organization {
             ...OrganizationParts
           }      
         }
         aggregate {
           count
         }  
       }
       total_aggregate: stewardships_aggregate {
         aggregate {
           count
         }
       }
     }
   }
   ${Fragments.organizationParts}    
 `},
   AreaOrganizations : function(sort,direction) { return gql`
   query AreaOrganizations($areaId: Int!,$limit: Int!, $offset: Int!) {    
     areas(where: {
       id: {
         _eq:$areaId
       }
     }) {
       organizations_aggregate: stewardships_aggregate(
         order_by: {organization:{${sort}:${direction}}},
         limit: $limit,
         offset: $offset) {                
         nodes {
           organization {
             ...OrganizationParts
           }      
         }
         aggregate {
           count
         }  
       }
       total_aggregate: stewardships_aggregate {
         aggregate {
           count
         }
       }
     }
   }
   ${Fragments.organizationParts}    
 `},
  UserOrganizations : function(sort,direction) { return gql`
    query UserOrganizations($userId: Int!,$limit: Int!, $offset: Int!) {    
      users(where: {
        id: {
          _eq:$userId
        }
      }) {
        organizations_aggregate: memberships_aggregate(
          order_by: {organization:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            organization {
              ...OrganizationParts
            }      
          }
          aggregate {
            count
          }  
        }
        total_aggregate: memberships_aggregate {
          aggregate {
            count
          }
        }

      }
    }
    ${Fragments.organizationParts}    
  `},
  Organizations : function(sort,direction) { return gql`
    query Organizations($limit: Int!, $offset: Int!) {    
      organizations_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
          nodes {
            ...OrganizationParts
          }
          aggregate {
            count
          }
        }    
        total_aggregate: organizations_aggregate {
        aggregate {
          count
        }
      }
    }
    ${Fragments.organizationParts}
  `},
  OrganizationNames: gql`
    query OrganizationNames {
      organizations{
        id
        name
      }
    }
  `,
  Organization: gql`
    query Organization($id: Int!) {
      organizations(
        where:{id:{_eq:$id}}) {
          ...OrganizationParts
      }
    }
    ${Fragments.organizationParts}
  `};