import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { 
  Typography, 
  LinearProgress,  
}  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {  
  Alert
} from '@material-ui/lab';

import Title from '../dashboard/Title';
import Queries from './PointOfInterestTypeQueries';
import Form from './PointOfInterestTypeForm'

const useStyles = makeStyles({
  root: {
    width: '100%',    
  },
});

function PointOfInterestType(){
  const classes = useStyles();
  let { id } = useParams();  
  const { loading, error, data } = useQuery(Queries.PointOfInterestType, 
    {
      variables: { id },
    });  

  if (loading) return <LinearProgress />;

  if (error) return (
    <Alert severity="error">{error.message}</Alert>
  );
  
  let pointOfInterestType = data.point_of_interest_types[0];

  return (
    <div className={classes.root}>
      <Title>Point of Interest Type</Title>
      <Typography variant="h5">{pointOfInterestType.name || `Unnamed`}</Typography>            
      <br/>
      <Form pointOfInterestType={pointOfInterestType} />

      <br/>
      <Link to="/point-of-interest-types">Back to Point of Interest Types</Link>
    </div>
  )
   
}

export default PointOfInterestType;