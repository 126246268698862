import { gql } from '@apollo/client';

export const Fragments = {
  areaParts: gql`
    fragment AreaParts on areas {
      id              
      name
      updated_at
      created_at
      org_count: stewardships_aggregate {
        aggregate {
          count
        }
      }
    }      
  `,
  contentParts: gql`
    fragment AreaContentParts on areas {
      area_content_blocks_aggregate {
        aggregate {
          count
        }
      }
      bookmarks_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      content_bundle_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      event_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      paper_map_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      points_of_interests_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      posts_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      stewardships_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      tags_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      trails_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      web_link_attachments_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
    }
  ` 
}

export default {
  OrganizationAreas: function(sort,direction) { return gql`
    query OrganizationAreas($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        areas_aggregate: area_stewardships_aggregate(
          order_by: {area:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            area {
              ...AreaParts  
              ...AreaContentParts              
            }                        
          }
          aggregate {
            count
          }  
        }
        total_aggregate: area_stewardships_aggregate {
          aggregate {
           count
          }
        }

      }
    }    
    ${Fragments.areaParts}
    ${Fragments.contentParts}
  `},
  Areas: function(sort,direction) { return gql`
    query Areas($limit: Int!, $offset: Int!) {    
      areas_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...AreaParts
          ...AreaContentParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate: areas_aggregate {
        aggregate {
          count
        }
      }
            
    }
    ${Fragments.areaParts}   
    ${Fragments.contentParts} 
  `},
  Area: gql`
    query Area($id: Int!) {
      areas(where:{id:{_eq:$id}}) {
        ...AreaParts
        stewardships: stewardships(order_by:{organization:{name:asc}}) {
          organization {
            id
            name
          }        
        }
      }
    }
    ${Fragments.areaParts}
  `,
  AreaContent: gql`
    query AreaContent($id: Int!) {
      areas(where:{id:{_eq:$id}}) {         
        id
        name
        accessibility_description
        address
        area_type
        audio_description
        area_content_blocks_aggregate {
          aggregate {
            count
          }
        }
        bookmarks_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        content_bundle_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        description
        created_at
        event_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        paper_map_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        parent_id
        phone_number
        points_of_interests_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        posts_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        slug
        stewardships_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        tags_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        trails_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
        updated_at
        user_id
        website
        web_link_attachments_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
      }
    }`
  }
