import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Image from './Image';
import ImageForm from './ImageForm';
import Title from '../dashboard/Title';
import ImagesTable from './ImagesTable';
import { ApolloProvider } from '@apollo/client';
import { useUploader } from '../../contexts/UploaderContext';

function Images() {
  let { path } = useRouteMatch();
  let uploaderClient = useUploader();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Images</Title>
        <ImagesTable />
      </Route>
      <Switch>
        <Route exact path={`${path}/new`}>
          <ApolloProvider client={uploaderClient}>
            <ImageForm />
          </ApolloProvider>
        </Route>
        <Route path={`${path}/:id`}>
          <Image />
        </Route>
      </Switch>
    </Switch>
  );
}

export default Images;
