import React from 'react';
import Queries from './CustomerQueries';
import DataTable from '../common/DataTable';

function CustomersTable({organizationId,areaId,trailId}) {

  const getQuery = (orderBy,order) => {
    let query = null;
    query = Queries.Customers(orderBy,order);    
    return query;
  }

  const getResults = (data) => {
    let results = []
    if(data) {
      results = data.customers_aggregate.nodes
    }
      
    return results;
  }
  
  const getTotalRows = (data) => {
    var result = null;
    result = data.total_aggregate.aggregate.count
    return result;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/customers/${value.id}`),
      },      

    ]
    
  return (
          
      <DataTable 
        defaultOrderBy="name"
        defaultOrder="asc"
        name="Customers" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
      />
    
  );  
}

export default CustomersTable;