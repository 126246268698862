import React from 'react';

import { 
  Tab,  
  Tabs,
}  from '@material-ui/core';

import OrganizationsTable from '../organizations/OrganizationsTable'
import ChallengesTable from '../challenges/ChallengesTable'
import PostsTable from '../posts/PostsTable'
import TabPanel from '../common/TabPanel'




export default function UserTabs({userId}){  
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs 
        indicatorColor="primary"
        textColor="primary"
        value={tabValue} 
        onChange={handleChange} 
        aria-label="simple tabs example">
        <Tab label="Organizations"/>
        <Tab label="Challenges"/>
        <Tab label="Posts"/>
      </Tabs>
      
      <TabPanel value={tabValue} index={0}>
        <OrganizationsTable userId={userId}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ChallengesTable userId={userId}/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <PostsTable userId={userId}/>
      </TabPanel>      
    </div>
  )
   
}