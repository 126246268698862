import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import Moment from 'react-moment';
import 'moment-timezone';

import { Alert } from '@material-ui/lab';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  TableSortLabel,
  LinearProgress,
} from '@material-ui/core';

import objectPath from 'object-path';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    verticalAlign: 'top',
  },
}));

const orderNulls = (order, nullsOrder) => {
  return nullsOrder ? `${order}_${nullsOrder}` : order;
};

function DataTable({
  name,
  rowMetadata,
  getQuery,
  queryVariables,
  getResults,
  getTotalRows,
  defaultOrderBy,
  defaultOrder,
  nullsOrder,
}) {
  const classes = useStyles();
  let [orderBy, setOrderBy] = useState(defaultOrderBy || 'id');
  let [order, setOrder] = useState(defaultOrder || 'desc');
  let [limit, setLimit] = useState(50);
  let [offset, setOffset] = useState(0);
  let [page, setPage] = useState(0);

  const onSort = (field) => {
    setOrderBy(field);
    if (field === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setOrder('asc');
    }
  };

  let { loading, error, data } = useQuery(getQuery(orderBy, orderNulls(order, nullsOrder)), {
    variables: {
      limit: limit || 50,
      offset: offset || 0,
      ...queryVariables,
    },
  });

  if (loading)
    return (
      <Paper className={classes.paper}>
        <LinearProgress />
      </Paper>
    );
  if (error) return <Alert severity="error">{error.message}</Alert>;

  let results = getResults(data);
  let totalRows = getTotalRows ? getTotalRows(data) : 1000;

  const handleChangePage = (event, page) => {
    setOffset(page * limit);
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setOffset(0);
    setLimit(event.target.value);
  };

  return (
    <TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500]}
        component="div"
        count={totalRows}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Table className={classes.table} size="small" aria-label={`${name} table`}>
        <TableHead>
          <TableRow>
            {rowMetadata.map((row) => (
              <TableCell key={row.field}>
                {row.sortable && (
                  <TableSortLabel
                    active={orderBy === row.field}
                    direction={orderBy === row.field ? (order === 'asc' ? 'asc' : 'desc') : 'asc'}
                    onClick={() => {
                      onSort(row.field);
                    }}
                  >
                    {row.label}
                  </TableSortLabel>
                )}
                {!row.sortable && row.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row) => {
            if (!row) {
              return null;
            }
            return (
              <TableRow key={row.id}>
                {rowMetadata.map((rowMeta) => {
                  let value = objectPath.get(row, rowMeta.field);

                  let displayValue = String(value);                  
                  if(value === undefined){
                    displayValue = (
                      <div>
                        -
                      </div>
                    );
                  } 
                  if (rowMeta.field === 'created_at' || rowMeta.field === 'updated_at' || rowMeta.field === 'confirmed_at') {
                    if (value === null) {
                      displayValue = (
                        <div>
                          <i>null</i>
                        </div>
                      );
                    } else {
                      displayValue = (
                      <div>
                        <Moment
                          format="YYYY-MM-DD HH:mm:ss"
                          tz="America/Los_Angeles"
                        >{`${value}Z`}</Moment>
                        <span>&nbsp;&middot;&nbsp;</span>
                        <i>
                          <Moment fromNow tz="America/Los_Angeles">{`${value}Z`}</Moment>
                        </i>
                      </div>
                    );
                    }
                  }
                  if (rowMeta.component) {
                    return(
                      <TableCell className={classes.tableCell} key={rowMeta.field}>
                        { rowMeta.component(row) }
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell className={classes.tableCell} key={rowMeta.field}>
                        {}
                        {rowMeta.linkFunction && value && (
                          <Link to={rowMeta.linkFunction(row)}>{displayValue}</Link>
                        )}
                        {rowMeta.linkFunction && value === undefined && <div>{displayValue}</div>}
                        {rowMeta.linkFunction && value === null && <div>{displayValue}</div>}
                        {!rowMeta.linkFunction && displayValue.toString() && (
                          <div>{displayValue}</div>
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500]}
        component="div"
        count={totalRows}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default DataTable;
