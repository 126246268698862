import React from 'react';

import Queries from './TrailQueries';

import DataTable from '../common/DataTable';

function TrailsTable({organizationId,areaId}) {
  const getQuery = (orderBy,order) => {    
    var query = organizationId ? 
    Queries.OrganizationTrails(orderBy,order) : 
    Queries.Trails(orderBy,order);
    return query;
  }

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId){
      result = data.organizations[0].total_aggregate.aggregate.count
    } else if (areaId) {
      result = data.areas[0].total_aggregate.aggregate.count
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }

  const getResults = (data) => {
    var results = []
    if(data){
      if(organizationId) {
        results = data.organizations[0].trails_aggregate.nodes.map(row=>row.trail)
      } else if (areaId) {
        results = data.areas[0].trails_aggregate.nodes.map(row=>row.trail)
      } else {
        results = data.trails_aggregate.nodes
      }
    }
    return results;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/trails/${value.id}`),
      },
      {
        field: 'org_count.aggregate.count',
        label: 'Organizations',
        sortable: false,
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
    ]
    
  return (        
      <DataTable 
        name="Trails" 
        rowMetadata={rowMetadata} 
        getResults={getResults}
        getTotalRows={getTotalRows}
        getQuery={getQuery}
        queryVariables={{organizationId:organizationId}}
      />    
  );  
}

export default TrailsTable;