import React from 'react';
import Queries from './PointOfInterestQueries';
import DataTable from '../common/DataTable';

function PointsOfInterestTable({organizationId,userId}) {
  const getQuery = (orderBy,order) => {
    let query = null
    if(organizationId) {
      query = Queries.OrganizationPointsOfInterest(orderBy,order)
    } else if(userId) {
      query = Queries.UserPointsOfInterest(orderBy,order)
    } else {
      query = Queries.PointsOfInterest(orderBy,order);
    }
    return query
  }

  const getResults = (data) => {
    let results = []
    if(data) {
      if(organizationId) {
        results = data.organizations[0].points_of_interest_aggregate.nodes.map(row=>row.point_of_interest);
      } else if(userId) {
        results = data.users[0].points_of_interest_aggregate.nodes
      } else {
        results = data.points_of_interest_aggregate.nodes
      }
    }
    return results
  }  

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId) {
      result = data.organizations[0].total_aggregate.aggregate.count;    
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }


  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
        linkFunction: (value) => (`/points-of-interest/${value.id}`),
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/points-of-interest/${value.id}`),
      },
      {
        field: 'point_of_interest_type.name',
        label: 'Type',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
    ]
    
  return (
          
      <DataTable 
        name="Points of Interest" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
        queryVariables={
          {
            organizationId:organizationId,            
          }        
        }
      />
    
  );  
}

export default PointsOfInterestTable;