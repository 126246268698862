import React from 'react'
import {
  Field,
  Form,
  Formik
} from 'formik'
import {
  object as YupObject,
  string as YupString,
  number as YupNumber,
  setLocale as YupSetLocale
} from 'yup'
import { TextField, Checkbox } from 'formik-material-ui'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  LinearProgress,
  Button,
  Grid
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import InsertOrganization from './queries/InsertOrganization'
import UpdateOrganization from './queries/UpdateOrganization'

// Set default Yup error messages:
YupSetLocale({
  number: {
    default: 'Must be a number.',
    integer: 'Must be an integer value.',
    positive: 'Must be a positive number.'
  },
  string: {
    max: 'Too long!',
    min: 'Too short!',
    required: 'Required!'
  }
})

const OrganizationSchema = YupObject().shape({
  description: YupString()
    .min(3),
  name: YupString()
    .min(3)
    .required(),
  license_tier: YupString()
    .required(),
  logo_image_id: YupNumber()
    .positive()
    .integer(),
  membership_limit: YupNumber()
    .positive()
    .integer()
    .required()
})

const defaultValues = {
  description: '',
  name: '',
  email_domain: '',
  is_verified: false,
  license_tier: 'free',
  logo_image_id: '',
  membership_limit: 2,
  slug: ''
}

const handleError = (error) => {
  console.warn(error)
}

export default function OrganizationForm ({ organization, onSave }) {
  const history = useHistory()
  const isNew = organization.id === 'new'

  const [insertOrganization, { loading: insertLoading }] = useMutation(InsertOrganization, { onError: handleError })
  const [updateOrganization, { loading: updateLoading }] = useMutation(UpdateOrganization, { onError: handleError })

  const initialValues = { ...organization } || defaultValues
  const isLoading = insertLoading || updateLoading

  // Since GraphQL returns null values for empty fields, we need to changed these to empty strings
  //  in order for Formik to have controlled inputs
  const initializeValues = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)))

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    let organizationData = (({ description, email_domain, is_verified, license_tier, logo_image_id, membership_limit, name, slug }) => {
      return { description, email_domain, is_verified, license_tier, logo_image_id, membership_limit, name, slug }
    })(values)

    // Add id value for existing organization
    if (!isNew) {
      organizationData = { id: values.id, ...organizationData }
    }

    // Normalize empty string data from Formik to our null default values
    if (organizationData.email_domain === '') { organizationData.email_domain = null }
    if (organizationData.license_tier === '') { organizationData.license_tier = null }
    if (organizationData.logo_image_id === '') { organizationData.logo_image_id = null }
    if (organizationData.membership_limit === '') { organizationData.membership_limit = null }
    if (organizationData.slug === '') { organizationData.slug = null }

    if (isNew) {
      insertOrganization({
        variables: {
          organization: organizationData
        }
      }).then((response) => {
        const id = response.data.insert_organizations_one.id
        history.push(`/organizations/${id}`)
        onSave()
        setSubmitting(false)
      })
    } else {
      updateOrganization({
        variables: organizationData
      }).then(() => {
        onSave()
        setSubmitting(false)
      })
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues(initialValues)}
      onSubmit={handleSubmit}
      validationSchema={OrganizationSchema}
    >
      {({ dirty, isSubmitting, isValid, submitForm, values }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label='Name'
                name='name'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label='Description'
                name='description'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                fullWidth
                label='Logo Image ID'
                name='logo_image_id'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label='Email Domain'
                name='email_domain'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                fullWidth
                label='Membership Limit'
                name='membership_limit'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label='License Tier'
                name='license_tier'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Field
                    component={Checkbox}
                    checked={values.is_verified}
                    name='is_verified'
                    type='checkbox'
                  />
                }
                label='Is Verified'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label='Slug'
                name='slug'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                color='primary'
                disabled={!isValid || isSubmitting || isLoading || !dirty}
                onClick={submitForm}
                variant='contained'
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
