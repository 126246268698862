import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { SimpleFileUpload } from 'formik-material-ui';
import { LinearProgress, Button, Grid } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useUploader } from '../../contexts/UploaderContext';
import { useHistory } from "react-router-dom";

const schema = Yup.object().shape({
  user_id: Yup.number().required('Required'),
});

const defaultValues = {
  user_id: null
};

const SINGLE_IMAGE_MUTATION = gql`
  mutation ProfileImage($photo_file: Upload!, $user_id: Int!) {
    profileImage(photo_file: $photo_file, user_id: $user_id) {
      id
      user_id
      photo_file
    }
  }
`;

export default function ProfileForm({ profile }) {
  let history = useHistory()
  const uploaderClient = useUploader();  
  const [updateProfileImage] = useMutation(SINGLE_IMAGE_MUTATION, { client: uploaderClient });      

  return (
    <Formik
      initialValues={profile || defaultValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);     
        
        updateProfileImage({ variables: values }).then((response) => {            
          setSubmitting(false);               
          history.push(`/users/${response.data.profileImage.user_id}`)
        });
      }}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={SimpleFileUpload}
                name="photo_file"
                fullWidth={true}
                variant="outlined"
                label="File"
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
}
