import React from 'react';

import Queries from './AreaQueries';

import DataTable from '../common/DataTable';

function AreasTable({organizationId}) {
  const getQuery = (orderBy,order) => {    
    var query = organizationId ? 
    Queries.OrganizationAreas(orderBy,order) : 
    Queries.Areas(orderBy,order);
    return query;
  }

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId){
      result = data.organizations[0].total_aggregate.aggregate.count
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }

  const getResults = (data) => {
    var results = data ? 
    (organizationId ? 
      data.organizations[0].areas_aggregate.nodes.map(row=>row.area) : 
      data.areas_aggregate.nodes) : [];
    return results;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/areas/${value.id}`),
      },
      {
        field: 'org_count.aggregate.count',
        label: 'Organizations',
        sortable: false,
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
    ]
    
  return (        
      <DataTable 
        name="Areas" 
        rowMetadata={rowMetadata} 
        getResults={getResults}
        getTotalRows={getTotalRows}
        getQuery={getQuery}
        queryVariables={{organizationId:organizationId}}
      />    
  );  
}

export default AreasTable;