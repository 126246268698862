import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import PointOfInterest from './PointOfInterest';
import Title from '../dashboard/Title';
import PointsOfInterestTable from './PointsOfInterestTable';

function PointsOfInterest() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Points of Interest</Title>
        <PointsOfInterestTable/>
      </Route>
      <Route path={`${path}/:id`}>
        <PointOfInterest />
      </Route>
    </Switch>

  );  
  
}

export default PointsOfInterest;