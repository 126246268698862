import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { Typography, LinearProgress }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../dashboard/Title';
import Queries from './ChallengeQueries';
import ChallengeEnrollmentsTable from './ChallengeEnrollmentsTable';

const useStyles = makeStyles({
  root: {
    width: '100%',    
  },
});

function Challenge(){
  const classes = useStyles();
  let { id } = useParams();
  
  const { loading, error, data } = useQuery(Queries.Challenge, 
    {
      variables: { challengeId: id },
    });  

  if (loading) return <LinearProgress />;
  if (error) return <p>Error :( {error.message}</p>;

  let {name} = data.challenges[0];

  return (
    <div className={classes.root}>
      <Title>Challenge</Title>
      <Typography variant="h5">{name || `Unnamed`}</Typography>      
      <div>
          < Title>Enrollments</Title>
          <ChallengeEnrollmentsTable challengeId={id}/>
        </div>

      <Link to="/challenges">Back to Challenges</Link>
    </div>
  )
   
}

export default Challenge;