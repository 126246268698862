import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Typography, LinearProgress, CardContent, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Alert } from '@material-ui/lab';

import Title from '../dashboard/Title';
import Queries from './FileQueries';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

function File() {
  const classes = useStyles();
  let { id } = useParams();

  const { loading, error, data } = useQuery(Queries.File, {
    variables: { id },
  });

  if (loading) return <LinearProgress />;

  if (error) return <Alert severity="error">{error.message}</Alert>;

  let image = data.images[0];

  return (
    <div className={classes.root}>
      <Title>File</Title>
      <Typography variant="h5">{image.uploaded_file || `Unnamed`}</Typography>

      <br />
      <Card>
        <CardContent>
          <img
            alt={image.caption}
            src={`https://cdn.outerspatial.com/uploads/image/uploaded_file/${image.id}/medium_${image.uploaded_file}`}
          />
        </CardContent>
      </Card>
      <br />
      <Link to="/images">Back to Files</Link>
    </div>
  );
}

export default File;
