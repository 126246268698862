import React from 'react';

import { AppBar, Tab, Tabs } from '@material-ui/core';

import OrganizationsTable from '../organizations/OrganizationsTable';
import CommunitiesTable from '../communities/CommunitiesTable';
import TabPanel from '../common/TabPanel';
import ImagesTable from '../images/ImagesTable';
import ImageForm from '../images/ImageForm';
import FilesTable from '../files/FilesTable';
import FileForm from '../files/FileForm';

export default function AreaTabs({ areaId }) {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Images" />
          <Tab label="Documents" />
          <Tab label="Communities" />
          <Tab label="Organizations" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <ImageForm
          image={{
            feature_id: parseInt(areaId),
            feature_type: 'Area',
            caption: 'New Area Image',
            position: 0,
          }}
        />
        <ImagesTable areaId={areaId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FileForm
          file={{
            feature_id: parseInt(areaId),
            feature_type: 'Area',
            name: 'New Area File',
          }}
        />
        <FilesTable areaId={areaId} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CommunitiesTable areaId={areaId} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <OrganizationsTable areaId={areaId} />
      </TabPanel>
    </div>
  );
}
