import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { Typography, LinearProgress, CardContent, Card }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {  
  Alert
} from '@material-ui/lab';

import Title from '../dashboard/Title';
import Queries from './PostQueries';

const useStyles = makeStyles({
  root: {
    width: '100%',    
  },
});

function Post(){
  const classes = useStyles();
  let { id } = useParams();
  
  const { loading, error, data } = useQuery(Queries.Post, 
    {
      variables: { id },
    });  

  if (loading) return <LinearProgress />;

  if (error) return (
    <Alert severity="error">{error.message}</Alert>
  );
  
  let post = data.posts[0];

  return (
    <div className={classes.root}>
      <Title>Post</Title>
      <Typography variant="h5">{post.post_type || `Unnamed`}</Typography>            

      <br/>
      <Card>
        <CardContent>
          <div dangerouslySetInnerHTML={{__html: post.post_text}} />        
        </CardContent>
      </Card>
      <br/>
      <Link to="/posts">Back to Posts</Link>
    </div>
  )
   
}

export default Post;