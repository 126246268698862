import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Areas from '../areas/Areas';
import Challenges from '../challenges/Challenges';
import Communities from '../communities/Communities';
import Files from '../files/Files';
import Images from '../images/Images';
import ObjectLogs from '../object-logs/ObjectLogs';
import Organizations from '../organizations/Organizations';
import PointOfInterestTypes from '../points-of-interest-types/PointOfInterestTypes';
import PointsOfInterest from '../points-of-interest/PointsOfInterest';
import Posts from '../posts/Posts';
import Search from '../search/Search';
import Trails from '../trails/Trails';
import Users from '../users/Users';
import Debug from './Debug';
import Tools from './Tools';
import { Tiles } from './Tiles';
import Customers from '../customers/Customers';
import ChangeEmailForm from '../users/ChangeEmailForm';

export default function DashboardRoutes() {
  return (
    <div>
      <Switch>
        <Route path="/change-email" component={ChangeEmailForm} />
        <Route path="/debug" component={Debug} />
        <Route path="/tools" component={Tools} />
        <Route path="/tiles" component={Tiles} />
        <Route path="/images" component={Images} />
        <Route path="/files" component={Files} />
        <Route path="/communities" component={Communities} />
        <Route path="/organizations" component={Organizations} />
        <Route path="/customers" component={Customers} />
        <Route path="/users" component={Users} />
        <Route path="/posts" component={Posts} />
        <Route path="/challenges" component={Challenges} />
        <Route path="/areas" component={Areas} />
        <Route path="/trails" component={Trails} />
        <Route path="/points-of-interest" component={PointsOfInterest} />
        <Route path="/point-of-interest-types" component={PointOfInterestTypes} />
        <Route path="/object-logs" component={ObjectLogs} />
        <Route path="/search" component={Search} />
        <Route>
          <Redirect to="/search" />
        </Route>
      </Switch>
    </div>
  );
}
