import React, { useState } from 'react';
import ReactMapGL, {ScaleControl,GeolocateControl} from 'react-map-gl';

const geolocateStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 10
};

const scaleControlStyle = {
  position: 'absolute',
  right: 0,
}

function ExploreMap({onClick,mapStyle}) {
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8
  });
  
  return (
    <ReactMapGL
      {...viewport}
      height="100vh"
      width="100%"
      mapStyle={mapStyle}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      onViewportChange={setViewport}   
      onClick={onClick}>      
      <GeolocateControl
        style={geolocateStyle}
        positionOptions={{enableHighAccuracy: true}}
        trackUserLocation={true}
      />
      <div style={{position: 'absolute', top: 10, right: 10}}>
        <ScaleControl
          maxWidth={100}
          unit="imperial"
          style={scaleControlStyle}
        />
      </div>
    </ReactMapGL>
  );
}

export default ExploreMap;