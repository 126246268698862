import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Trail from './Trail';
import Title from '../dashboard/Title';
import TrailsTable from './TrailsTable';
import TrailContentTable from './TrailContentTable';

function Trails() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Trails</Title>
      <TrailsTable/>
      </Route>
      <Route path={`${path}/content`}>
        <Title>Content</Title>
        <TrailContentTable/>
      </Route>        
      <Route path={`${path}/:id`}>
        <Trail />
      </Route>
    </Switch>

  );  
  
}

export default Trails;