import React from 'react';

import { 
  Tab,  
  Tabs,
}  from '@material-ui/core';


// import UsersTable from '../users/UsersTable'
// import CommunitiesTable from '../communities/CommunitiesTable'
import AreasTable from '../areas/AreasTable'
import AreaContentTable from '../areas/AreaContentTable'
import TabPanel from '../common/TabPanel'
import UsersTable from '../users/UsersTable'
// import ImagesTable from '../images/ImagesTable'

export default function OrganizationTabs({id}){
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {    
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs 
        indicatorColor="primary"
        textColor="primary"        
        value={tabValue}
        onChange={handleChange} 
        aria-label="simple tabs example">
        <Tab label="Users"/>
        <Tab label="Areas"/>
        <Tab label="Area Content"/>        
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <UsersTable organizationId={id}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AreasTable organizationId={id}/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <AreaContentTable organizationId={id}/>
      </TabPanel>
    </div>
  )
   
}