import { gql } from '@apollo/client';

export default gql`
    mutation InsertCustomer(
      $customer: customers_insert_input!
    ) {
      insert_customers_one(
        object: $customer
      ) {
        id
      }
    }
`