import { gql } from '@apollo/client';

export default gql`
    mutation RemoveOrganization($communityId: Int!, $organizationId: Int!) {
      delete_community_memberships(where: {
        community_id: {_eq: $communityId},
        member_id: {_eq: $organizationId},
        member_type: {_eq: "Organization"}
      }) {
        affected_rows
        returning {
          id
          member_id
        }
      }
    }
`