import { gql } from '@apollo/client';

export default gql`
    mutation AddOrganizations($objects: [community_memberships_insert_input!]!)
     {
      insert_community_memberships(objects: $objects) {
        affected_rows
        returning {
          id
          created_at
          updated_at
        }
      }
    }
`