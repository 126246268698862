import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { 
  Typography, 
  LinearProgress }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../dashboard/Title';
import Queries from './ObjectLogQueries';

const useStyles = makeStyles({
  root: {
    width: '100%',    
  },
});


function ObjectLog(){
  const classes = useStyles();
  let { id } = useParams();

  const { loading, error, data } = useQuery(Queries.ObjectLog, 
    {
      variables: { id },
    });  

  if (loading) return <LinearProgress />;
  if (error) return <p>Error :( {error.message}</p>;

  let {name,email} = data.users[0];

  return (
    <div className={classes.root}>
      <Title>ObjectLog</Title>
      <Typography variant="h5">{name || `Unnamed`}</Typography>      
      <Typography variant="h6">{email || `Unnamed`}</Typography>                  
      <Link to="/object-logs">Back to ObjectLogs</Link>
    </div>
  )
   
}

export default ObjectLog;