import { gql } from '@apollo/client'

export default gql`
  mutation UpdateOrganization(
    $description: String!,
    $email_domain: String,
    $id: Int!,
    $is_verified: Boolean!,
    $logo_image_id: Int,
    $name: String!,
    $slug: String
  ) {            
    update_organizations(
      where: {id: {_eq: $id}}
        _set: {
          description: $description,
          email_domain: $email_domain,
          is_verified: $is_verified,
          logo_image_id: $logo_image_id,
          name: $name,
          slug: $slug
        }
    ) {
      affected_rows
      returning {
        created_at
        description
        email_domain
        id
        is_verified
        logo_image_id
        name
        slug
        updated_at
      }
    }
  }
`
