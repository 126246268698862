import { gql } from '@apollo/client';

export const Fragments = {
  userParts: gql`
    fragment UserParts on users {
      id
      email
      updated_at
      created_at
      confirmation_sent_at
      confirmed_at
      invitation_created_at
      invitation_sent_at
      invitation_accepted_at
      profile {
        id
        user_id
        name
        photo_file
      }
      org_count: memberships_aggregate {
        aggregate {
          count
        }
      }
    }
  `,
};

export default {
  OrganizationUsers: function (sort, direction) {
    return gql`
    query OrganizationUsers($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        users_aggregate: memberships_aggregate(
          order_by: {user:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            user {
              ...UserParts    
            }            
          }
          aggregate {
            count
          }  
        }
        total_aggregate: memberships_aggregate {
          aggregate {
            count
          }
        }

      }
    }    
    ${Fragments.userParts}
  `;
  },
  Users: function (sort, direction) {
    return gql`
    query Users($limit: Int!, $offset: Int!) {    
      users_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...UserParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate: users_aggregate {
        aggregate {
          count
        }
      }
            
    }
    ${Fragments.userParts}    
  `;
  },
  User: gql`
    query User($id: Int!) {
      users(where: { id: { _eq: $id } }) {
        ...UserParts
        organizations: memberships(order_by: { organization: { name: asc } }) {
          organization {
            id
            name
          }
        }
      }
    }
    ${Fragments.userParts}
  `,
};
