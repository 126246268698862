import { gql } from '@apollo/client'

export default gql`
    mutation InsertOrganization(
      $organization: organizations_insert_input!
    ) {
      insert_organizations_one(
        object: $organization
      ) {
        id
      }
    }
`
