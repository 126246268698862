import React from 'react';
import {
  Grid, Typography,
} from '@material-ui/core';


import Title from './Title';

let ToolLink = ({children}) => {
  return(
    <li>
      <Typography variant="h6">{children}</Typography>
      <br/>
    </li>
  )
}

function Tools() {
  
  return (
    
    <Grid container>
      <Grid item xs={12}>
        <Title>Tools</Title>
      </Grid>
      
      <Grid item xs={12}>
      
        <ul>
          <ToolLink>
            <a href="https://manager.outerspatial.com">Manager</a>
          </ToolLink>
          <ToolLink>
            <a href="https://graphql.outerspatial.com">GraphQL Console</a>
          </ToolLink>
          <ToolLink>
            <a href="https://cloud.digitalocean.com/projects/346c5588-141f-4fb2-bd8d-a5ede14632f6/resources?i=905a35">
              DigitalOcean
            </a>
          </ToolLink>
          <ToolLink>
            <a href="https://app.segment.com/trailhead-labs/overview">Segment</a>
          </ToolLink>
          <ToolLink>
            <a href="https://analytics.amplitude.com/outerspatial/workspace">Amplitude</a>
          </ToolLink>
          <ToolLink>
            <a href="https://console.aws.amazon.com/console/home?region=us-east-1">AWS Console</a>
          </ToolLink>
          <ToolLink>
            <a href="https://github.com/trailheadlabs">Github</a>
          </ToolLink>          
        </ul> 
             
        
      </Grid>
      
    </Grid>
    
  );  
  
}

export default Tools;