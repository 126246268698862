import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Paper, Button } from '@material-ui/core';
import Title from '../dashboard/Title';
import Queries from './CommunityQueries';
import RemoveOrganization from './queries/RemoveOrganization';
import CommunityOrganizationsTable from './CommunityOrganizationsTable';
import CommunityForm from './CommunityForm';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  link: {
    padding: '10px',
  },
  paper: {
    padding: '18px',
  },
});

const defaultValues = {
  id: 'new',
  name: '',
  description: '',
  badge_image_id: '',
  hero_image_id: '',
  organizations: [],
  is_sponsored: false,
  slug: '',
};

const handleError = (error) => {
  console.warn(error);
};

function Community() {
  const classes = useStyles();
  let { id } = useParams();

  const [exporting, setExporting] = React.useState(false);

  const handleClickExportSqlite = () => {
    console.log('Exporting sqlite');
    setExporting(true);
    const apiUrl = `${process.env.REACT_APP_EXPORT_API_URL}/export/sqlite/one`;
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    }).then((response) => {
      if (response.ok) {
        alert('Export successful');
      }
      setExporting(false);
    });
  };

  const { loading, error, data, refetch } = useQuery(Queries.Community, {
    variables: { id },
    skip: id === 'new',
  });

  const [removeOrganization] = useMutation(RemoveOrganization, {
    // In order to force the cache to refresh, we'll just lazily refetch for now.
    refetchQueries: [
      {
        query: Queries.Community,
        variables: {
          id: id,
        },
      },
    ],
    onError: handleError,
  });

  const handleRemoveOrganization = (organizationId) => {
    removeOrganization({
      variables: {
        organizationId: organizationId,
        communityId: community.id,
      },
    });
  };

  let community = data ? data.communities[0] : defaultValues;

  if (loading) return <LinearProgress />;

  // TODO: Handle use case of data.communities = [] where an invalid communityId was requested
  let organizations = data ? data.communities[0].organizations : [];

  return (
    <div className={classes.root}>
      <Title>Community</Title>
      <Button
        color="primary"
        variant="contained"
        disabled={exporting}
        onClick={handleClickExportSqlite}
      >
        Export SQLite
      </Button>

      <Paper className={classes.paper}>
        <h2>{community.name}</h2>
        {error && <p>{error.message}</p>}
        <CommunityForm community={community} onSave={refetch} />
      </Paper>
      <br />
      {organizations && organizations.length > 0 && (
        <div>
          <Title>Organizations</Title>
          <CommunityOrganizationsTable
            communityId={id}
            orderBy={'name'}
            order={'asc'}
            removeOrganizationFromCommunity={handleRemoveOrganization}
          />
        </div>
      )}
      <div className={classes.link}>
        <Link to="/communities">Back to Communities</Link>
      </div>
    </div>
  );
}

export default Community;
