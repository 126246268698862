import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Paper } from '@material-ui/core';
import Title from '../dashboard/Title';
import Queries from './CustomerQueries';
import CustomerForm from './CustomerForm'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  link: {
    padding: '10px',
  },
  paper: {
    padding: '18px',
  }
});

const defaultValues = {
  id: 'new',
  name: "",
  description: "",
  badge_image_id: "",
  hero_image_id: "",
  organizations: [],
  is_sponsored: false,
  slug: ""
}

function Customer(){
  const classes = useStyles();
  let { id } = useParams();

  const { loading, error, data, refetch } = useQuery(
    Queries.Customer,
    {
      variables: { id },
      skip: id === 'new'
    }
  );

  let customer = data ? data.customers[0] : defaultValues

  if (loading) return <LinearProgress />

  return (
    <div className={classes.root}>
      <Title>Customer</Title>
      <Paper className={classes.paper}>
        <h2>{customer.name}</h2>
         { error &&
          <p>{error.message}</p>
        }
        <CustomerForm customer={customer} onSave={refetch}/>
      </Paper>
      <br/>
      <div className={classes.link}>
        <Link to="/customers">Back to Communities</Link>
      </div>

    </div>
  )

}

export default Customer;