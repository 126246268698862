import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, SimpleFileUpload } from 'formik-material-ui';
import { LinearProgress, Button, Grid } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useUploader } from '../../contexts/UploaderContext';
import { useHistory } from "react-router-dom";

const schema = Yup.object().shape({
  caption: Yup.string().min(4, 'Too Short!').max(255, 'Too Long!').required('Required'),
});

const defaultValues = {
  caption: 'New Image',
};

const SINGLE_IMAGE_MUTATION = gql`
  mutation SingleImage($uploaded_file: Upload!, $caption: String!) {
    singleImage(uploaded_file: $uploaded_file, caption: $caption) {
      id
    }
  }
`;

const SINGLE_IMAGE_ATTACHMENT_MUTATION = gql`
  mutation SingleImageAttachment(
    $uploaded_file: Upload!
    $caption: String!
    $feature_type: String!
    $feature_id: Int!
    $position: Int!
  ) {
    singleImageAttachment(
      position: $position
      feature_type: $feature_type
      feature_id: $feature_id
      uploaded_file: $uploaded_file
      caption: $caption
    ) {
      id
      image {
        id
      }
    }
  }
`;

export default function ImageForm({ image }) {
  let history = useHistory()
  const uploaderClient = useUploader();  
  const [uploadImageMutation] = useMutation(SINGLE_IMAGE_MUTATION, { client: uploaderClient });
  const [uploadImageAttachmentMutation] = useMutation(SINGLE_IMAGE_ATTACHMENT_MUTATION, {
    client: uploaderClient,
  });

  return (
    <Formik
      initialValues={image || defaultValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);     
        if (values.feature_id) {
          uploadImageAttachmentMutation({ variables: values }).then((response) => {            
            setSubmitting(false);     
            history.push(`/images/${response.data.singleImageAttachment.image.id}`)
          });
        } else {
          uploadImageMutation({
            variables: { caption: values.caption, uploaded_file: values.uploaded_file },
          }).then((response) => {
            setSubmitting(false);     
            history.push(`/images/${response.data.singleImage.id}`)                       
          });
        }
      }}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="caption"
                label="Caption"
                variant="outlined"
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={SimpleFileUpload}
                name="uploaded_file"
                fullWidth={true}
                variant="outlined"
                label="File"
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
}
