import { gql } from '@apollo/client';

export default gql`
    mutation InsertCommunity(
      $community: communities_insert_input!
    ) {
      insert_communities_one(
        object: $community
      ) {
        id
      }
    }
`