import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'typeface-roboto';

import App from './App';

import packageJson from '../package.json';

import {
  ThemeProvider,
  CssBaseline,
  colors,
  createTheme,
} from '@material-ui/core';

import * as serviceWorker from './serviceWorker';

import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#3bafda',
    },
    secondary: {
      main: '#3e3e3f',
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: '#f8f4ec',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#ffffff',
      },
    },
  },
});

console.log(`OuterSpatial Platform v${packageJson.version}`);

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
