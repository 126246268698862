import { gql } from '@apollo/client';

export const Fragments = {
  PointOfInterestParts: gql`
    fragment PointOfInterestParts on points_of_interest {
      id
      name
      point_of_interest_type {
        id
        name
        tag_key
      }
      created_at
      updated_at
      location {
        geometry
      }
    }
  `}

export default {
  OrganizationPointsOfInterest : function(sort,direction) { return gql`
    query OrganizationPoints($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        points_of_interest_aggregate(
          order_by: {post:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            ...PointOfInterestParts
          }
          aggregate {
            count
          }  
        }
      }
    }
    ${Fragments.PointOfInterestParts}
  `},
  PointsOfInterest : function(sort,direction) { return gql`
    query Posts($limit: Int!, $offset: Int!) {    
      points_of_interest_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...PointOfInterestParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate :
        points_of_interest_aggregate {
          aggregate {
            count
          }
        }      
      }
       
    ${Fragments.PointOfInterestParts}
  `},
  PointOfInterest: gql`
    query PointOfInterest($id: Int!) {
      points_of_interest(where:{id:{_eq:$id}}) {
        ...PointOfInterestParts
      }      
    }
    ${Fragments.PointOfInterestParts}
  `};