import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Typography, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Title from '../dashboard/Title'
import Queries from './TrailQueries'
import TrailTabs from './TrailTabs'

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

function Trail () {
  const classes = useStyles()
  const { id } = useParams()

  const { loading, error, data } = useQuery(Queries.Trail, {
    variables: { id }
  })

  if (loading) return <LinearProgress />
  if (error) return <p>Error :( {error.message}</p>

  const { name } = data.trails[0]

  return (
    <div className={classes.root}>
      <Title>Trail</Title>
      <Typography variant='h5'>{name || 'Unnamed'}</Typography>
      <TrailTabs trailId={id} />
      <Link to='/trails'>Back to Trails</Link>
    </div>
  )
}

export default Trail
