import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import User from './User';
import Title from '../dashboard/Title';
import UsersTable from './UsersTable';

function Users() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Users</Title>
        <UsersTable/>
      </Route>
      <Route path={`${path}/:id`}>
        <User />
      </Route>
    </Switch>

  );  
  
}

export default Users;