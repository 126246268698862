import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Title from '../dashboard/Title'
import OrganizationForm from './OrganizationForm'

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative'
  },
  link: {
    padding: '10px'
  },
  paper: {
    padding: '18px'
  }
})

const defaultValues = {
  description: '<p>New Organization</p>',
  name: 'New Organization'
}

const handleOnSave = () => {
  window.alert('Created new organization.')
}

function Organization () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Title>New Organization</Title>
      <Paper className={classes.paper}>
        <OrganizationForm onSave={handleOnSave} organization={defaultValues} />
      </Paper>
      <div className={classes.link}>
        <Link to='/organizations'>Back to Organizations</Link>
      </div>
    </div>
  )
}

export default Organization
