import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import packageJson from '../../../package.json';
import { AuthContext } from '../../contexts/AuthContext';
import { UploaderContext } from '../../contexts/UploaderContext';
import DashboardRoutes from './DashboardRoutes';
import useStyles from './DashboardStyles';
import Explore from './Explore';
import Navigation from './Navigation';

export default function Dashboard() {
  const classes = useStyles();
  const [graphqlErrors /* ,setGraphqlErrors*/] = useState();
  const { user, getAccessTokenSilently, getIdTokenClaims, logout, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = React.useState();

  useEffect(() => {    
    try {
      if (!accessToken && isAuthenticated) {
        getAccessTokenSilently().then((data) => {
          setAccessToken(data);
        });
      }
    } catch {
      logout('Exception in getToken');
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) console.error(`[Network error]: ${networkError.message}`);
    if (graphQLErrors)
      console.error(`[GraphQL errors]: ${graphQLErrors.map((item) => item.message)}`);
    if (graphQLErrors) {
      if (graphQLErrors[0].extensions.code === 'access-denied') {
        logout();
      } else {
        // setGraphqlErrors(graphQLErrors)
      }
    } else if (networkError) {
      logout('Network Error');
    }
  });

  const setAuthorizationLink = setContext(() => ({
    headers: { authorization: `Bearer ${accessToken}` },
  }));

  const link = from([
    setAuthorizationLink,
    errorLink,
    new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL }),
  ]);

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  const graphqlClient = new ApolloClient({
    name: 'platform-manager',
    version: packageJson.version,
    cache: new InMemoryCache({
      typePolicies: {
        communities: {
          fields: {
            organization_community_memberships: {
              merge(existing = [], incoming) {
                return [...existing, ...incoming]; // Safely merge the organizations when we delete one of them from the community
              },
            },
          },
        },
      },
    }),
    link: link,
    defaultOptions,
  });

  const uploaderClient = new ApolloClient({
    name: 'platform-manager',
    version: packageJson.version,
    cache: new InMemoryCache({
      typePolicies: {
        communities: {
          fields: {
            organization_community_memberships: {
              merge(existing = [], incoming) {
                return [...existing, ...incoming]; // Safely merge the organizations when we delete one of them from the community
              },
            },
          },
        },
      },
    }),
    link: link,
    defaultOptions,
  });

  if (!accessToken) {
    return <LinearProgress />;
  } else {
    return (
      <ApolloProvider client={graphqlClient}>
        <AuthContext.Provider value={accessToken}>
          <UploaderContext.Provider value={uploaderClient}>
            <div className={classes.root}>
              <Navigation currentUser={user} logout={logout} />
              <main className={classes.content}>
                <Switch>
                  <Route exact path="/landscape-map">
                    <Box className={classes.mapBox} id="dashboardMapbox">
                      <Explore mapStyle="mapbox://styles/trailheadlabs/cjwv5p9tnhecy1cp9yv4xb635" />
                    </Box>
                  </Route>
                  <Route exact path="/xray-map">
                    <Box className={classes.mapBox} id="dashboardMapbox">
                      <Explore mapStyle="mapbox://styles/trailheadlabs/ckoojv97x1gzw17p5nfw721ti" />
                    </Box>
                  </Route>
                  <Route exact path="/view-content-map">
                    <Box className={classes.mapBox} id="dashboardMapbox">
                      <Explore mapStyle="mapbox://styles/trailheadlabs/ckoortb4w9klu18q75n4oy8cr" />
                    </Box>
                  </Route>
                  <Route path="/">
                    <Container maxWidth={false} className={classes.container}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            {graphqlErrors && (
                              <Alert severity="error">
                                <AlertTitle>GraphQL Errors</AlertTitle>
                                <ul>
                                  {graphqlErrors.map((error) => {
                                    return <li>{error.message}</li>;
                                  })}
                                </ul>
                              </Alert>
                            )}
                            <DashboardRoutes />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Container>
                  </Route>
                </Switch>
              </main>
            </div>
          </UploaderContext.Provider>
        </AuthContext.Provider>
      </ApolloProvider>
    );
  }
}
