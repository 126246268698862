import { gql } from '@apollo/client';

export const Fragments = {
  mediaFileParts: gql`
    fragment MediaFileParts on media_files {
      id
      name
      uploaded_file
      updated_at
      created_at
    }
  `,
};

export default {
  OrganizationFiles: function (sort, direction) {
    return gql`
    query OrganizationFiles($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        media_files_aggregate: media_file_attachments_aggregate(
          order_by: {media_file:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            media_file {
              ...FileParts
            }      
          }
          aggregate {
            count
          }  
        }
      }
    }
    ${Fragments.mediaFileParts}    
  `;
  },
  AreaFiles: function (sort, direction) {
    return gql`
    query AreaFiles($areaId: Int!,$limit: Int!, $offset: Int!) {    
      areas(where: {
        id: {
          _eq:$areaId
        }
      }) {
        media_files_aggregate: media_file_attachments_aggregate(
          order_by: {media_file:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            media_file {
              ...MediaFileParts
            }      
          }
          aggregate {
            count
          }  
        }
      }
    }
    ${Fragments.mediaFileParts}    
  `;
  },

  Files: function (sort, direction) {
    return gql`
    query Files($limit: Int!, $offset: Int!) {    
      media_files_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...MediaFileParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate : media_files_aggregate {      
        aggregate {
          count
        }
      }        
    }    
    ${Fragments.mediaFileParts}
  `;
  },
  File: gql`
    query File($id: Int!) {
      media_files(where: { id: { _eq: $id } }) {
        ...MediaFileParts
      }
    }
    ${Fragments.mediaFileParts}
  `,
};
