import { gql } from '@apollo/client';

export default {

  Customers : function(sort,direction) { return gql`
    query Customers($limit: Int!, $offset: Int!) {    
      customers_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          id
          name            
          updated_at
        }
        aggregate { 
          count
        }
      }
      total_aggregate: customers_aggregate {
        aggregate {
          count
        }
      }
    }    
  `},
  Customer: gql`
    query Customers($id: Int!) {
      customers(where:{id:{_eq:$id}}) {
        id
        name
        description
        created_at
      }
    }
  `};