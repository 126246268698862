import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import ObjectLog from './ObjectLog';
import Title from '../dashboard/Title';
import ObjectLogsTable from './ObjectLogsTable';

function ObjectLogs() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Object Logs</Title>
        <ObjectLogsTable/>
      </Route>
      <Route path={`${path}/:id`}>
        <ObjectLog />
      </Route>
    </Switch>

  );  
  
}

export default ObjectLogs;