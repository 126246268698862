import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Customer from './Customer';
import NewCustomer from './NewCustomer';
import CustomersList from './CustomersList';

function Customers() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CustomersList />
      </Route>
      <Route path={`${path}/:id`}>
        <Customer />
      </Route>
      <Route path={`${path}/new`}>
        <NewCustomer />
      </Route>
    </Switch>
  );

}

export default Customers;