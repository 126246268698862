import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Title from '../dashboard/Title';
import { Button } from '@material-ui/core';
import CommunitiesTable from './CommunitiesTable'

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  newButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
});

function CommunityList(){
  const classes = useStyles();
  let history = useHistory();

  const handleOnClick = () => {
    history.push(`/communities/new`)
  }

  return (
    <div className={classes.root}>
      <Title>Communities</Title>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleOnClick}
        className={classes.newButton}
      >
        Create New Community
      </Button>
      <CommunitiesTable/>
    </div>
  )
}

export default CommunityList;