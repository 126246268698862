import React from 'react';
import Queries from './ChallengeQueries';
import DataTable from '../common/DataTable';

function ChallengeEnrollmentsTable({challengeId}) {
  
  const getQuery = (orderBy,order) => {
    var query = challengeId ? 
      Queries.Challenge : 
      Queries.Challenges(orderBy,order);
    return query;
  }
  const getResults = (data) => {
    let results = data ? 
      (challengeId ? 
        data.challenges[0].enrollments_aggregate.nodes : 
        data.enrollments_aggregate.nodes) : []
    return results; 
  }

  const getTotalRows = (data) => {
    return data.challenges[0].enrollments_aggregate.aggregate.count;
  }

  const queryVariables = {
    challengeId: challengeId
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'user.email',
        label: 'User',
        sortable: true,
        linkFunction: (value) => (`/users/${value.user.id}`),
      },
      {
        field: 'enrolled_at',
        label: 'Enrolled At',    
        sortable: true,    
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
    ]
  
  return (
          
      <DataTable 
        name="Challenge Enrollments" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery} 
        getResults={getResults}
        queryVariables={queryVariables}
        getTotalRows={getTotalRows}
      />
    
  );  
}

export default ChallengeEnrollmentsTable;