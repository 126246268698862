import React from 'react';
import Queries from './ChallengeQueries';
import DataTable from '../common/DataTable';

function ChallengesTable({organizationId,userId}) {
  
  const getQuery = (orderBy,order) => {
    var query = null;
    if(organizationId) {
      query = Queries.OrganizationChallenges(orderBy,order)
    } else if(userId) {
      query = Queries.UserChallenges(orderBy,order)    
    } else {
      query = Queries.Challenges(orderBy,order);
    }
      
    return query;
  }

  const getResults = (data) => {
    var results = null; 
    
    if(organizationId) {       
      results = data.organizations[0].challenges_aggregate.nodes.map(row=>row.challenge) 
    } else if (userId) {
      results = data.users[0].challenge_enrollments_aggregate.nodes.map(row=>row.challenge) 
    } else {
      results = data.challenges_aggregate.nodes;
    }
    return results;
  }

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId) {
      result = data.organizations[0].total_aggregate.aggregate.count;
    } else if (userId) {
      result = data.users[0].total_aggregate.aggregate.count;
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/challenges/${value.id}`),
      },
      {
        field: 'description',
        label: 'Description',        
      },
      {
        field: 'enrollments_aggregate.aggregate.count',
        label: 'Enrollments',        
      },
      {
        field: 'start_date',
        label: 'Start Date',
        sortable: true,
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        sortable: true,
      },
      {
        field: 'created_at',
        label: 'Created At',
        sortable: true,
      },
    ]
    
  return (
          
      <DataTable 
        name="Challenges" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
        queryVariables={
          {
            organizationId:organizationId,
            userId:userId,
          }
        }
      />
    
  );  
}

export default ChallengesTable;