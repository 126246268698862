import { gql } from '@apollo/client';

export const Fragments = {
  PointOfInterestTypeParts: gql`
    fragment PointOfInterestTypeParts on point_of_interest_types {
      id
      name  
      tag_key
      category
      rank
      created_at
      updated_at
      points_of_interest_aggregate {
        aggregate {
          count
        }
      }
    }
  `}

export default {  
  PointOfInterestTypes : function(sort,direction) { return gql`
    query PointOfInterestTypes($limit: Int!, $offset: Int!) {    
      point_of_interest_types_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...PointOfInterestTypeParts
        }
        aggregate { 
          count
        }
      }
      total_aggregate :
        point_of_interest_types_aggregate {
          aggregate {
            count
          }
        }      
      }
       
    ${Fragments.PointOfInterestTypeParts}
  `},
  PointOfInterestType: gql`
    query PointOfInterestType($id: Int!) {
      point_of_interest_types(where:{id:{_eq:$id}}) {
        ...PointOfInterestTypeParts
      }      
    }
    ${Fragments.PointOfInterestTypeParts}
  `,
  UpdatePointOfInterestType: gql`
    mutation UpdatePointOfInterestType(
      $id: Int!,
      $name: String!, 
      $tag_key: String!, 
      $rank: String!,
      $category: String!
    ) {
      update_point_of_interest_types(
        where:{
          id:{
            _eq:$id
          }
        },
        _set: {
          name: $name,
          tag_key: $tag_key,
          rank: $rank,
          category: $category
        })
        {
          affected_rows
          returning {
          ...PointOfInterestTypeParts
        }        
      }      
    }
    ${Fragments.PointOfInterestTypeParts}
  `
  };