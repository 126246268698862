import { Box, LinearProgress } from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import React from 'react';
import { useQuery } from '@apollo/client';
import SearchQuery from './queries/Search';

export default function SearchResults({term}) {    
  
  let id_term = parseInt(term)
  
  const { error, loading, data } = useQuery(SearchQuery,
    {variables: {
      term: `%${term}%`,
      id_term: isNaN(id_term) ? 0 : id_term,
      uid_term: term,
      limit: 200
    }});  

  if (loading) return <LinearProgress />;

  if (error) return (
    <Alert severity="error">{error.message}</Alert>
  );
  if (!data) {
    return(
      <h3>No Results</h3>
    )
  }
  return (
    <Box>
      <Box>
        { data.o.length > 0 &&          
          <h2>Organizations - {data.o.length}</h2>
        }
        {data.o.map(
          (item) => {
            return (
              <Box key={`organizations-${item.id}`}>
                <a href={`/organizations/${item.id}`}>{item.id} : {item.name || '[blank]'}</a>
                &nbsp;-&nbsp;
                <a href={`https://www.outerspatial.com/organizations/${item.id}`}>Web</a>
              </Box>
            )
          }
        )}   
      </Box>
      <Box>  
        { data.u.length > 0 &&             
          <h2>Users - {data.u.length}</h2>
        }
        {data.u.map(
          (item) => {
            return (
              <Box key={`users-${item.id}`}>
                <a href={`/users/${item.id}`}>{item.id} : {item.email || '[blank]'}</a>
                &nbsp;-&nbsp;
                <a href={`https://www.outerspatial.com/members/${item.id}`}>Web</a>
              </Box>
            )
          }
        )}
      </Box>
      <Box>  
        { data.i.length > 0 &&             
          <h2>Images - {data.i.length}</h2>
        }
        {data.i.map(
          (item) => {
            return (
              <Box key={`images-${item.id}`}>
                <a href={`/images/${item.id}`}>{item.id} : {item.caption || '[blank]'}</a>
                &nbsp;-&nbsp;
                <a href={`https://www.outerspatial.com/images/${item.id}`}>Web</a>
              </Box>
            )
          }
        )}
      </Box>
      <Box>
        { data.a.length > 0 &&             
          <h2>Areas - {data.a.length}</h2>
        }
        {data.a.map(
          (item) => {
            return (
              <Box key={`users-${item.id}`}>
                <a href={`https://www.outerspatial.com/areas/${item.id}`}>{item.id} : {item.name || '[blank]'}</a>
              </Box>
            )
          }
        )}
      </Box>
      <Box>
        { data.t.length > 0 &&             
          <h2>Trails  - {data.t.length}</h2>
        }
        {data && data.t.map(
          (item) => {
            return <Box key={`trail-${item.i}`}><a href={`https://www.outerspatial.com/trails/${item.i}`}>{item.i} : {item.n || '[blank]'}</a></Box>
          }
        )}
      </Box>
      <Box>
        { data.p.length > 0 &&             
          <h2>Points of Interest  - {data.p.length}</h2>
        }
        {data && data.p.map(
          (item) => {
            return <Box key={`poi-${item.id}`}><a href={`https://www.outerspatial.com/points-of-interest/${item.id}`}>{item.id} : {item.name || ['blank']}</a></Box>
          }
        )}
      </Box>
      <Box>
        { data.out.length > 0 &&             
          <h2>Outings - {data.out.length}</h2>
        }
        {data.out.map(
          (item) => {
            return (
              <Box key={`users-${item.id}`}>
                <a href={`https://www.outerspatial.com/outings/${item.id}`}>{item.id} : {item.name}</a>
              </Box>
            )
          }
        )}
      </Box>
      <Box>
        { data.e.length > 0 &&             
          <h2>Events - {data.e.length}</h2>
        }
        {data.e.map(
          (item) => {
            return (
              <Box key={`events-${item.id}`}>
                <a href={`https://www.outerspatial.com/events/${item.id}`}>{item.id} : {item.name}</a>
              </Box>
            )
          }
        )}
      </Box>
    </Box>
  );  
  
}

