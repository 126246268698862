import React from 'react'

import { Tab, Tabs } from '@material-ui/core'

import OrganizationsTable from '../organizations/OrganizationsTable'
import CommunitiesTable from '../communities/CommunitiesTable'
import TabPanel from '../common/TabPanel'
import ImagesTable from '../images/ImagesTable'
import ImageForm from '../images/ImageForm'
import FilesTable from '../files/FilesTable'
import FileForm from '../files/FileForm'

export default function TrailTabs ({ trailId }) {
  const [tabValue, setTabValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div>
      <Tabs
        indicatorColor='primary'
        textColor='primary'
        value={tabValue}
        onChange={handleChange}
        aria-label='simple tabs example'
      >
        <Tab label='Detail' />
        <Tab label='Images' />
        <Tab label='Documents' />
        <Tab label='Communities' />
        <Tab label='Organizations' />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <ImageForm
          image={{
            feature_id: parseInt(trailId),
            feature_type: 'Trail',
            caption: 'New Trail Image',
            position: 0
          }}
        />
        <ImagesTable trailId={trailId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FileForm
          file={{
            feature_id: parseInt(trailId),
            feature_type: 'Trail',
            name: 'New Trail File'
          }}
        />
        <FilesTable trailId={trailId} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CommunitiesTable trailId={trailId} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <OrganizationsTable trailId={trailId} />
      </TabPanel>
    </div>
  )
}
