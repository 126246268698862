import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Community from './Community';
import NewCommunity from './NewCommunity';
import CommunitiesList from './CommunitiesList';

function Communities() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CommunitiesList />
      </Route>
      <Route path={`${path}/:id`}>
        <Community />
      </Route>
      <Route path={`${path}/new`}>
        <NewCommunity />
      </Route>
    </Switch>
  );

}

export default Communities;