import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/AuthContext';
import { LinearProgress, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';

const schema = Yup.object().shape({
  old_email: Yup.string().required('Required'),
  new_email: Yup.string().required('Required'),
});

const defaultValues = {
  old_email: 'jereme@outerspatial.com',
  new_email: 'me@jmoe.com',
};

export default function ChangeEmailForm() {
  let history = useHistory();
  const accessToken = useAuth();
  const requestChangeEmail = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/change-email`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldEmail: values.old_email,
        newEmail: values.new_email,
      }),
    });
    setSubmitting(false);

    if (response.ok) {
      alert('Email changed successfully');
      history.push(`/users`);
    } else {
      const body = await response.json();
      alert(body.message);
    }
  };

  return (
    <Formik initialValues={defaultValues} validationSchema={schema} onSubmit={requestChangeEmail}>
      {({ submitForm, isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label="Old Email"
                name="old_email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label="New Email"
                name="new_email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
}
