import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Post from './Post';
import Title from '../dashboard/Title';
import PostsTable from './PostsTable';

function Posts() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Posts</Title>
        <PostsTable/>
      </Route>
      <Route path={`${path}/:id`}>
        <Post />
      </Route>
    </Switch>

  );  
  
}

export default Posts;