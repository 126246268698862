import React from 'react'
import Queries from './ImageQueries'
import DataTable from '../common/DataTable'

function ImagesTable ({ organizationId, areaId, trailId }) {
  const getQuery = (orderBy, order) => {
    let query = null

    if (organizationId) {
      query = Queries.OrganizationImages(orderBy, order)
    } else if (areaId) {
      query = Queries.AreaImages(orderBy, order)
    } else if (trailId) {
      query = Queries.TrailImages(orderBy, order)
    } else {
      query = Queries.Images(orderBy, order)
    }

    return query
  }

  const getResults = (data) => {
    let results = []

    if (data) {
      if (organizationId) {
        results = data.organizations[0].images_aggregate.nodes.map((row) => row.image)
      } else if (areaId) {
        results = data.areas[0].images_aggregate.nodes.map((row) => row.image)
      } else if (trailId) {
        results = data.trails[0].images_aggregate.nodes.map((row) => row.image)
      } else {
        results = data.images_aggregate.nodes
      }
    }

    return results
  }

  const getTotalRows = (data) => {
    let result = null

    if (organizationId) {
      result = data.organizations[0].images_aggregate.aggregate.count
    } else if (areaId) {
      result = data.areas[0].images_aggregate.aggregate.count
    } else if (trailId) {
      result = data.trails[0].images_aggregate.aggregate.count
    } else {
      result = data.total_aggregate.aggregate.count
    }

    return result
  }

  let rowMetadata = [
    {
      field: 'id',
      label: 'ID',
      sortable: true,
      linkFunction: (value) => `/images/${value.id}`
    },
    {
      field: 'caption',
      label: 'Caption',
      sortable: true
    },
    {
      field: 'uploaded_file',
      label: 'File',
      sortable: true,
      component: (row) => {
        return (
          <img alt={row.caption || 'Image'} src={`https://cdn.outerspatial.com/uploads/image/uploaded_file/${row.id}/small_${row.uploaded_file}`}/>
        )
      }
    },
    {
      field: 'updated_at',
      label: 'Updated At',
      sortable: true
    },
    {
      field: 'created_at',
      label: 'Created At',
      sortable: true
    }
  ]

  return (
    <DataTable
      name='Images'
      rowMetadata={rowMetadata}
      getQuery={getQuery}
      getResults={getResults}
      getTotalRows={getTotalRows}
      queryVariables={{
        organizationId: organizationId,
        areaId: areaId,
        trailId: trailId
      }}
    />
  )
}

export default ImagesTable
