import { gql } from '@apollo/client';

export const Fragments = {
  challengeParts: gql`
    fragment ChallengeParts on challenges {
      id              
      name
      description
      start_date
      updated_at
      created_at
    }
  `,
  enrollmentParts: gql`
  fragment EnrollmentParts on challenge_enrollments {
    id              
    user{
      id
      email
    }
    enrolled_at    
    updated_at
    created_at
  }      
`
}

export default {
  UserChallenges : function(sort,direction) { return gql`
    query UserChallenges($userId: Int!,$limit: Int!, $offset: Int!) {    
      users(where: {
        id: {
          _eq:$userId
        }
      }) {
        challenge_enrollments_aggregate(
          order_by: {challenge:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            challenge{
              ...ChallengeParts    
            }            
          }
          aggregate {
            count
          }  
        }
        total_aggregate: challenge_enrollments_aggregate {
          aggregate {
            count
          }
        }
      }
    }    
    ${Fragments.challengeParts}
  `},
  OrganizationChallenges : function(sort,direction) { return gql`
    query OrganizationChallenges($organizationId: Int!,$limit: Int!, $offset: Int!) {    
      organizations(where: {
        id: {
          _eq:$organizationId
        }
      }) {
        challenges_aggregate(
          order_by: {challenge:{${sort}:${direction}}},
          limit: $limit,
          offset: $offset) {                
          nodes {
            ...ChallengeParts    
          }
          aggregate {
            count
          }  
        }
        total_aggregate: challenges_aggregate {
          aggregate {
            count
          }
        }

      }
    }    
    ${Fragments.challengeParts}
  `},
  Challenges : function(sort,direction) { return gql`
    query Challenges($limit: Int!, $offset: Int!) {    
      challenges_aggregate(        
        order_by:{${sort}:${direction}},
        limit: $limit,
        offset: $offset) {      
        nodes {
          ...ChallengeParts

          enrollments_aggregate{
            nodes {
              ...EnrollmentParts
            }
            aggregate {
              count
            }
          }
        }
        aggregate { 
          count
        }
      }
      total_aggregate: challenges_aggregate {
        aggregate {
          count
        }
      }

    }
    ${Fragments.challengeParts}
    ${Fragments.enrollmentParts}
  `},
  Challenge: gql`
    query Challenge($challengeId: Int!) {
      challenges(where:{id:{_eq:$challengeId}}) {
        ...ChallengeParts
        enrollments_aggregate{
          nodes {
            ...EnrollmentParts
          }
          aggregate {
            count
          }
        }
      }
    }
    ${Fragments.challengeParts}
    ${Fragments.enrollmentParts}
  `};