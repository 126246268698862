import { Box, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import SearchResults from './SearchResults'

export default function Search() {    
  const [term,setTerm] = useState();  

  return (
    <Box>
      <Box>              
          <TextField                       
            name="term"
            variant="outlined"
            required
            fullWidth
            id="term"
            label="Search"
            autoFocus
            onChange={(event)=>{setTerm(event.target.value)}}
          />                
      </Box>
      { term && term.length > 2 &&
        <SearchResults term={term}/>
      }
    </Box>
  );  
  
}

