import { gql } from '@apollo/client';

export default gql`
    mutation UpdateCommunity(
      $id: Int!,
      $name: String!,
      $description: String!,
      $boundary: geometry,
      $badge_image_id: Int,
      $hero_image_id: Int,
      $slug: String
    ) {
      update_communities(
        where: {id: {_eq: $id}}
          _set: {
            name: $name,
            description: $description,
            badge_image_id: $badge_image_id,
            hero_image_id:$hero_image_id,
            slug:$slug
          }
      ) {
        affected_rows
        returning {
          id
          name
          created_at
          updated_at
          description
          badge_image_id
          hero_image_id
          boundary {
            geometry
          }
          slug
        }
      }
    }
`