import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { 
  Card,
  CardContent,
  CardActions,
  Typography,  
} from '@material-ui/core';

const cardStyles = makeStyles({
  root: {
    width: '100%',
    margin:'14px',  
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  noPadding: {
    padding: '0px',
  }
  
});

function ExploreFeatures({features}) {
  const cardClasses = cardStyles();

  if(features && features.length === 0) {
    return (
      <Card className={cardClasses.root}>
        <CardContent>
        <Typography className={cardClasses.pos} color="textSecondary">
          Explore
        </Typography>
        Click on the map to get more info.
        </CardContent>
      </Card>
    )
  } else {
    return (
    features.slice().reverse().map((feature,index) => {
      return (
          <Card key={index} className={cardClasses.root}>
            <CardContent>
            {feature.map((item,featureIndex) => {
              var displayItem = {                          
                type: item.type,
                properties: item.properties,
                layer: {
                  id: item.layer.id,
                  sourceLayer: item.layer['source-layer'],
                }
              }
              return(
                <Card key={featureIndex} className={cardClasses.root}>  
                  <CardContent>
                  <Typography className={cardClasses.pos} color="textSecondary">
                    {item.sourceLayer}
                  </Typography>
                  <pre>
                    {JSON.stringify(displayItem,null,2)}
                  </pre>                    
                  <CardActions>                              
                    { item.properties.class === 'Trail' &&
                      <a target="_blank" rel="noopener noreferrer" href={`https://www.outerspatial.com/trails/${item.properties.id}`}>Wev</a>
                    }                              
                    { item.properties.class === 'Area' &&
                      <a target="_blank" rel="noopener noreferrer" href={`https://www.outerspatial.com/areas/${item.properties.id}`}>Web</a>
                    }                              
                    { item.properties.class === 'PointOfInterest' &&
                      <a target="_blank" rel="noopener noreferrer" href={`https://www.outerspatial.com/points_of_interest/${item.properties.id}`}>Web</a>
                    }                              
                  </CardActions>
                  </CardContent>                   
                </Card>
              )})
            }
            </CardContent>
            
          </Card>
      
      )}       
    ))
  
  }
}

export default ExploreFeatures