import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Area from './Area';
import Title from '../dashboard/Title';
import AreasTable from './AreasTable';
import AreaContentTable from './AreaContentTable';

function Areas() {
  
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Areas</Title>
      <AreasTable/>
      </Route>
      <Route path={`${path}/content`}>
        <Title>Content</Title>
        <AreaContentTable/>
      </Route>        
      <Route path={`${path}/:id`}>
        <Area />
      </Route>
    </Switch>

  );  
  
}

export default Areas;