import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { LinearProgress, makeStyles, Paper, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import OrganizationForm from './OrganizationForm'
import OrganizationTabs from './OrganizationTabs'
import Queries from './OrganizationQueries.js'
import Title from '../dashboard/Title'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from '../../contexts/AuthContext'

const useStyles = makeStyles({
  link: {
    padding: '10px'
  },
  paper: {
    padding: '18px'
  },
  root: {
    width: '100%'
  }
})

const defaultValues = {
  id: 'new',
  description: '',
  name: '',
  email_domain: '',
  is_verified: false,
  logo_image_id: '',
  slug: ''
}

async function purge (accessToken, id, featureType = 'pois') {
  const apiUrl = `${process.env.REACT_APP_API_URL}/organizations/purge`
  const response = await fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify({
      id: parseInt(id),      
      featureType: featureType
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` }
  })

  if (response.ok) {
    window.alert('Purge successful')
  }

  return response
}

function createExport (accessToken, id, setExporting) {
  setExporting(true)
  const apiUrl = `${process.env.REACT_APP_EXPORTS_URL}/export/organizations`
  return fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify({
      organization_ids: [parseInt(id)],
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` }
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Could not export.')
      }
    })
    .then((data) => {
      if (data.error) {
        throw new Error('Could not export.')
      }
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      setExporting(false)
    })
}

const insertMembershipMutation = gql`
  mutation InsertMembership($organization_id: Int!, $user_id: Int!) {
    insert_memberships(
      objects: { organization_id: $organization_id, user_id: $user_id, role: "admin" }
    ) {
      returning {
        id
      }
    }
  }
`;

const Organization = () => {
  const { id } = useParams();
  const { user } = useAuth0();
  const accessToken = useAuth();
  const [createMembership] = useMutation(insertMembershipMutation, {
    refetchQueries: ['OrganizationUsers'],
  });

  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(Queries.Organization, {
    skip: id === 'new',
    variables: { id }
  })
  const [exporting, setExporting] = useState(false);

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const organization = data ? data.organizations[0] : defaultValues;

  const joinOrganization = () => {
    const user_id = user['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    createMembership({
      variables: {
        organization_id: parseInt(id),
        user_id: user_id,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Title>Organization</Title>
      <Paper className={classes.paper}>
        <h2>{organization.name}</h2>
        <OrganizationForm onSave={refetch} organization={organization} />
      </Paper>
      {organization && organization.id !== 'new' && (
        <>
          <OrganizationTabs id={organization.id} />
          <Button color="primary" variant="contained" onClick={joinOrganization}>
            Join Organization
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            disabled={exporting}
            onClick={() => {
              createExport(accessToken, id, setExporting);
            }}
          >
            Export
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              confirm('Are you sure you want to purge this organization?') &&
                purge(accessToken, id, 'areas');
            }}
          >
            Purge Areas
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              confirm('Are you sure you want to purge this organization?') &&
                purge(accessToken, id, 'pois');
            }}
          >
            Purge POIs
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              confirm('Are you sure you want to purge this organization?') &&
                purge(accessToken, id, 'trails');
            }}
          >
            Purge Trails
          </Button>
          <div className={classes.link}>
            <a href={`${process.env.REACT_APP_EXPORTS_URL}/exported/organizations/${id}`}>
              Exports
            </a>
            &nbsp;&nbsp;&nbsp;
            <Link to="/organizations">Back to Organizations</Link>
          </div>
        </>
      )}
      {organization && organization.id === 'new' && (
        <div className={classes.link}>
          <Link to="/organizations">Back to Organizations</Link>
        </div>
      )}
    </div>
  );
};

export default Organization;
