import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, SimpleFileUpload } from 'formik-material-ui';
import { LinearProgress, Button, Grid } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useUploader } from '../../contexts/UploaderContext';

const schema = Yup.object().shape({
  name: Yup.string().min(4, 'Too Short!').max(255, 'Too Long!').required('Required'),
});

const defaultValues = {
  name: 'New File',
};

const SINGLE_FILE_MUTATION = gql`
  mutation SingleFile($uploaded_file: Upload!, $name: String!) {
    singleFile(uploaded_file: $uploaded_file, name: $name) {
      id
    }
  }
`;

const SINGLE_FILE_ATTACHMENT_MUTATION = gql`
  mutation SingleFileAttachment(
    $uploaded_file: Upload!
    $name: String!
    $feature_type: String!
    $feature_id: Int!
  ) {
    singleFileAttachment(
      feature_type: $feature_type
      feature_id: $feature_id
      uploaded_file: $uploaded_file
      name: $name
    ) {
      id
      file {
        id
      }
    }
  }
`;

export default function FileForm({ file }) {
  const uploaderClient = useUploader();
  const [uploadFileMutation] = useMutation(SINGLE_FILE_MUTATION, { client: uploaderClient });
  const [uploadFileAttachmentMutation] = useMutation(SINGLE_FILE_ATTACHMENT_MUTATION, {
    client: uploaderClient,
  });

  return (
    <Formik
      initialValues={file || defaultValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if (values.feature_id) {
          uploadFileAttachmentMutation({
            variables: {
              name: values.name,
              feature_type: values.feature_type,
              feature_id: parseInt(values.feature_id),
              uploaded_file: values.uploaded_file,
            },
          });
        } else {
          uploadFileMutation({
            variables: { name: values.name, uploaded_file: values.uploaded_file },
          });
        }
      }}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="name"
                label="Name"
                variant="outlined"
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={TextField}
                name="feature_type"
                fullWidth={true}
                variant="outlined"
                label="Feature Type"
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={TextField}
                name="feature_id"
                fullWidth={true}
                variant="outlined"
                label="Feature ID"
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={SimpleFileUpload}
                name="uploaded_file"
                fullWidth={true}
                variant="outlined"
                label="File"
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
}
