import { gql } from '@apollo/client';

export default gql`
    mutation UpdateCommunityBoundary(
      $id: Int!,
      $boundary: geometry      
    ) {
      delete_community_boundaries(where: {feature_type: {_eq: "Community"}, feature_id: {_eq: $id}, attribute_name: {_eq: "boundary"}}) {
        affected_rows
        returning {
          id
        }
      }
      insert_community_boundaries(
        objects: {
          geometry: $boundary,
          feature_id: $id
        }) {
          affected_rows
          returning {
              id
              geometry
          }        
      }      
    }
`