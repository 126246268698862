import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Title from '../dashboard/Title';
import { Button } from '@material-ui/core';
import OrganizationsTable from './OrganizationsTable'

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  newButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
});

function OrganizationList(){
  const classes = useStyles();
  let history = useHistory();

  const handleOnClick = () => {
    history.push(`/organizations/new`)
  }

  return (
    <div className={classes.root}>
      <Title>Organizations</Title>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleOnClick}
        className={classes.newButton}
      >
        Create New Organization
      </Button>
      <OrganizationsTable/>
    </div>
  )
}

export default OrganizationList;