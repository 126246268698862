import React from 'react';
import {
  Link,
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Title from '../dashboard/Title';
import CommunityForm from './CustomerForm'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  link: {
    padding: '10px',
  },
  paper: {
    padding: '18px',
  }
});

const defaultValues = {
  name: "New Community",
  description: "<p>New Community</p>"
}

const handleOnSave = () => {
  alert('Created new community.')
}

function Customer(){
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title>New Customer</Title>
      <Paper className={classes.paper}>
        <CommunityForm community={defaultValues} onSave={handleOnSave}/>
      </Paper>
      <div className={classes.link}>
        <Link to="/communities">Back to Communities</Link>
      </div>

    </div>
  )

}

export default Customer;