import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box, 
  Grid, } from '@material-ui/core';
import ExploreMap from './ExploreMap.js'
import ExploreFeatures from './ExploreFeatures.js'

const useStyles = makeStyles(theme => ({
  container: {
    width:'100%',
    height:'100vh',
  },
  drawer: {
    width:'240px',
    flexShrink: 0,
  },
  featureItems:{
    overflow: 'scroll',
    maxHeight: '100vh',
    width:'100%',
  }
}));

function Explore({mapStyle}) {
  const classes = useStyles();
  
  const [features,setFeatures] = useState([])

  const onClick = (pointerEvent) => {    
    setFeatures(features.concat([pointerEvent.features]))    
  }

  return (
      <Grid container spacing={0}>
        <Grid item xs={8} className={classes.noPadding}>              
          <Box id="exploreBox" className={classes.container}>          
            <ExploreMap onClick={onClick} mapStyle={mapStyle}/>
          </Box>
  
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={0} className={classes.featureItems}>
              <ExploreFeatures features={features}/>
          </Grid>          
        </Grid>
      </Grid>
       
  );
}

export default Explore;