import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { 
  Typography, 
  LinearProgress, 
}  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {  
  Alert
} from '@material-ui/lab';

import Title from '../dashboard/Title';
import Queries from './PointOfInterestQueries';

const useStyles = makeStyles({
  root: {
    width: '100%',    
  },
});

function PointOfInterest(){
  const classes = useStyles();
  let { id } = useParams();
  
  const { loading, error, data } = useQuery(Queries.PointOfInterest, 
    {
      variables: { id },
    });  

  if (loading) return <LinearProgress />;

  if (error) return (
    <Alert severity="error">{error.message}</Alert>
  );
  
  let pointOfInterest = data.points_of_interest[0];

  return (
    <div className={classes.root}>
      <Title>Point of Interest</Title>
      <Typography variant="h5">{pointOfInterest.name || `Unnamed`}</Typography>            

      <br/>
      <Link to="/points-of-interest">Back to Points of Interest</Link>
    </div>
  )
   
}

export default PointOfInterest;