import React from 'react';

import Queries from './TrailQueries';

import DataTable from '../common/DataTable';

function TrailContentTable({organizationId}) {
  const getQuery = (orderBy,order) => {    
    var query = organizationId ? 
    Queries.OrganizationTrails(orderBy,order) : 
    Queries.Trails(orderBy,order);
    return query;
  }

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId){
      result = data.organizations[0].total_aggregate.aggregate.count
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }

  const getResults = (data) => {
    var results = data ? 
    (organizationId ? 
      data.organizations[0].trails_aggregate.nodes.map(row=>row.trail) : 
      data.trails_aggregate.nodes) : [];
    return results;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/trails/${value.id}`),
      },
      {
        field: 'content_blocks_aggregate.aggregate.count',
        label: 'Blocks',        
      },
      {
        field: 'content_bundle_attachments_aggregate.aggregate.count',
        label: 'Bundles',
      },
      {
        field: 'event_attachments_aggregate.aggregate.count',
        label: 'Events',        
      },
      {
        field: 'web_link_attachments_aggregate.aggregate.count',
        label: 'Links',        
      },      
      {
        field: 'paper_map_attachments_aggregate.aggregate.count',
        label: 'Maps',        
      },
      {
        field: 'posts_aggregate.aggregate.count',
        label: 'Posts',        
      },
      {
        field: 'bookmarks_aggregate.aggregate.count',
        label: 'Bookmarks',        
      },
      {
        field: 'stewardships_aggregate.aggregate.count',
        label: 'Stewards',        
      },
      {
        field: 'tags_aggregate.aggregate.count',
        label: 'Tags',        
      },
      
    ]
    
  return (        
      <DataTable 
        name="Trails" 
        rowMetadata={rowMetadata} 
        getResults={getResults}
        getTotalRows={getTotalRows}
        getQuery={getQuery}
        queryVariables={{organizationId:organizationId}}
      />    
  );  
}

export default TrailContentTable;