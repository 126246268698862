import React from 'react';
import Queries from './FileQueries';
import DataTable from '../common/DataTable';

function FilesTable({ organizationId, areaId }) {
  const getQuery = (orderBy, order) => {
    let query = null;
    if (organizationId) {
      query = Queries.OrganizationFiles(orderBy, order);
    } else if (areaId) {
      query = Queries.AreaFiles(orderBy, order);
    } else {
      query = Queries.Files(orderBy, order);
    }
    return query;
  };

  const getResults = (data) => {
    let results = [];
    if (data) {
      if (organizationId) {
        results = data.organizations[0].media_files_aggregate.nodes.map((row) => row.media_file);
      } else if (areaId) {
        results = data.areas[0].media_files_aggregate.nodes.map((row) => row.media_file);
      } else {
        results = data.media_files_aggregate.nodes;
      }
    }
    return results;
  };

  const getTotalRows = (data) => {
    let result = null;
    if (organizationId) {
      result = data.organizations[0].media_files_aggregate.aggregate.count;
    } else if (areaId) {
      result = data.areas[0].media_files_aggregate.aggregate.count;
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  };

  let rowMetadata = [
    {
      field: 'id',
      label: 'ID',
      sortable: true,
      linkFunction: (value) => `/files/${value.id}`,
    },
    {
      field: 'name',
      label: 'Name',
      sortable: true,
      linkFunction: (value) => `/files/${value.id}`,
    },
    {
      field: 'uploaded_file',
      label: 'File',
      sortable: true,
      linkFunction: (value) => `/files/${value.id}`,
    },
    {
      field: 'updated_at',
      label: 'Updated At',
      sortable: true,
    },
    {
      field: 'created_at',
      label: 'Created At',
      sortable: true,
    },
  ];

  return (
    <DataTable
      name="Files"
      rowMetadata={rowMetadata}
      getQuery={getQuery}
      getResults={getResults}
      getTotalRows={getTotalRows}
      queryVariables={{
        organizationId: organizationId,
        areaId: areaId,
      }}
    />
  );
}

export default FilesTable;
