import React from 'react'
import { useQuery } from '@apollo/client'
import { Autocomplete } from '@material-ui/lab'
import Organizations from './queries/Organizations'
import { LinearProgress, TextField   } from '@material-ui/core'

export default function OrganizationComboBox({label,onChange,value,filterIds, isMultiple = true}) {
    let { loading, data, error } = useQuery(Organizations)

    if(loading || !data ) {
        return <LinearProgress/>
    }

    if(error) {
        return <div>Could not load organizations.</div>
    }

    const handleChange = (event,value,reason) => {
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <Autocomplete
            id="combo-box-demo"
            options={data.organizations.filter((item)=>{return !filterIds.includes(item.id)})}
            getOptionLabel={(option) => option?.name ? option.name : ''}
            getOptionSelected={(option, value) => (value && (value.id === option.id))}
            multiple={isMultiple}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
            onChange={handleChange}
            value={value}
      />
    )
}