import React from 'react';

import Queries from './UserQueries';

import DataTable from '../common/DataTable';

function UsersTable({organizationId}) {
  const getQuery = (orderBy,order) => {    
    var query = organizationId ? 
    Queries.OrganizationUsers(orderBy,order) : 
    Queries.Users(orderBy,order);
    return query;
  }

  const getTotalRows = (data) => {
    let result = null;
    if(organizationId){
      result = data.organizations[0].total_aggregate.aggregate.count
    } else {
      result = data.total_aggregate.aggregate.count;
    }
    return result;
  }

  const getResults = (data) => {
    var results = data ? 
    (organizationId ? 
      data.organizations[0].users_aggregate.nodes.map(row=>row.user) : 
      data.users_aggregate.nodes) : [];
    return results;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'email',
        label: 'Email',
        sortable: true,
        linkFunction: (value) => (`/users/${value.id}`),
      },
      {
        field: 'org_count.aggregate.count',
        label: 'Organizations',
        sortable: false,
      },
      {
        field: 'created_at',
        label: 'Created',
        sortable: true,
      },
      {
        field: 'confirmed_at',
        label: 'Confirmed',
        sortable: true,
      },
      {
        field: 'invitation_sent_at',
        label: 'Invited',
        sortable: true,
      },
      {
        field: 'invitation_accepted_at',
        label: 'Accepted',
        sortable: true,
      },

    ]
    
  return (        
      <DataTable 
        name="Users" 
        rowMetadata={rowMetadata} 
        getResults={getResults}
        getTotalRows={getTotalRows}
        getQuery={getQuery}
        queryVariables={{organizationId:organizationId}}        
      />    
  );  
}

export default UsersTable;