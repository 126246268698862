import React from 'react';
import Queries from './CommunityQueries';
import DataTable from '../common/DataTable';

function CommunitiesTable({organizationId,areaId,trailId}) {

  const getQuery = (orderBy,order) => {
    let query = null;
    if(organizationId) {
      query = Queries.OrganizationCommunities(orderBy,order)
    } else if(areaId) {
      query = Queries.AreaCommunities(orderBy,order)
    } else if(trailId) {
      query = Queries.TrailCommunities(orderBy,order)
    } else {
      query = Queries.Communities(orderBy,order);
    }
    return query;
  }

  const getResults = (data) => {
    let results = []
    if(data) {
      if(organizationId) {
        results = data.organizations[0].communities_aggregate.nodes.map(row=>row.community)
      } else if(areaId) {
        results = data.community_areas_aggregate.nodes.map(row=>row.community)
      } else if(trailId) {
        results = data.community_trails_aggregate.nodes.map(row=>row.community)
      } else {
        results = data.communities_aggregate.nodes
      }
    }
      
    return results;
  }
  
  const getTotalRows = (data) => {
    var result = null;
    if(organizationId) {
      result = data.organizations[0].total_aggregate.aggregate.count  
    } else if(areaId) {
      result = data.community_areas_aggregate.aggregate.count  
    } else if(trailId) {
      result = data.community_trails_aggregate.aggregate.count  
    } else {
      result = data.total_aggregate.aggregate.count
    }
    return result;
  }

  let rowMetadata = [
      {
        field: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        linkFunction: (value) => (`/communities/${value.id}`),
      },
      {
        field: 'org_count.aggregate.count',
        label: 'Organizations',
        sortable: false,
      },
      {
        field: 'area_count.count',
        label: 'Areas',
        sortable: false,
      },
      {
        field: 'trail_count.count',
        label: 'Trails',
        sortable: false,
      },
      {
        field: 'trail_miles.length_miles',
        label: 'Miles',
        sortable: false,
      },
      {
        field: 'point_of_interest_count.count',
        label: 'POIs',
        sortable: false,
      },

    ]
    
  return (
          
      <DataTable 
        defaultOrderBy="name"
        defaultOrder="asc"
        name="Communities" 
        rowMetadata={rowMetadata} 
        getQuery={getQuery}
        getResults={getResults}
        getTotalRows={getTotalRows}
        queryVariables={
          {
            organizationId:organizationId,
            areaId:areaId,
            trailId:trailId
          }
        }
      />
    
  );  
}

export default CommunitiesTable;