import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import File from './File';
import FileForm from './FileForm';
import Title from '../dashboard/Title';
import FilesTable from './FilesTable';
import { ApolloProvider } from '@apollo/client';
import { useUploader } from '../../contexts/UploaderContext';

function Files() {
  let { path } = useRouteMatch();
  let uploaderClient = useUploader();

  return (
    <Switch>
      <Route exact path={path}>
        <Title>Files</Title>
        <FilesTable />
      </Route>
      <Switch>
        <Route exact path={`${path}/new`}>
          <ApolloProvider client={uploaderClient}>
            <FileForm />
          </ApolloProvider>
        </Route>
        <Route path={`${path}/:id`}>
          <File />
        </Route>
      </Switch>
    </Switch>
  );
}

export default Files;
